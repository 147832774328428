import React, { useContext, useState } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import { Form } from "react-bootstrap";
import { RefreshContext, DisplayContext } from "../context/event-context"
import { headerConfig } from "../helper/headerConfig";
const AdminList = ({ admins }) => {

    const [refreshKey, setRefreshKey] = useContext(RefreshContext);
    const [specificAdmin, setSpecificAdmin] = useState(false);
    const [id, setId] = useState();
    const [Name, setName] = useState();
    const [Username, setUsername] = useState();
    const [Password, setPassword] = useState();
    const [Email, setEmail] = useState();
    const [CellPhone, setCellPhone] = useState();
    const [showHeadings, setShowHeadings] = useState(true);
    const [updatePassword, setUpdatePassword] = useState(false);
    const [display, setDisplay] = useContext(DisplayContext);

    const updateAdmin = async (e) => {
        e.preventDefault();
        let updatedData = { _id: id, Name: Name, Username: Username, Email: Email, CellPhone: CellPhone };
        if (updatePassword) {
            updatedData["Password"] = Password
        }
        const config = headerConfig();
        axios.post(`${process.env.GATSBY_API_URL}/updateAdmin`, updatedData, config).then(() => {
            Swal.fire(
                'Content Saved!',
                'Admin Updated Successfully!',
                'success'
            );
            setDisplay([false, false, false, true, false, false, false]);
            setRefreshKey(refreshKey + 1);
        }).catch(err => alert(err));
    }

    return (
        <>
            {
                showHeadings &&
                <div className="admin-heading mx-auto">
                    <p>Action</p>
                    <p>Name</p>
                    <p>Email</p>
                    <p>Phone</p>
                </div>
            }
            {
                !specificAdmin &&
                admins?.map((user, i) => {
                    let _id = user._id;

                    const deleteAdmin = () => {
                        Swal.fire({
                            title: 'Delete this user?',
                            text: "You won't be able to revert this!",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Yes, delete it!'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                const config = headerConfig();
                                axios.post(`${process.env.GATSBY_API_URL}/deleteAdmin`, { _id }, config).then((data) => {
                                    Swal.fire(
                                        'Content Saved!',
                                        'Admin Deleted Successfully!',
                                        'success'
                                    );
                                    setRefreshKey(refreshKey + 1)
                                }).catch(err => console.log(err));
                            }
                        });
                    }
                    return <div key={i} className="adminList mb-3 mb-lg-0 mx-auto">
                        <div>
                            <button className="button-custom" onClick={
                                () => {
                                    setId(user._id);
                                    setName(user.Name);
                                    setEmail(user.Email);
                                    setUsername(user.Username);
                                    setCellPhone(user.CellPhone);
                                    setShowHeadings(false);
                                    setSpecificAdmin(true);
                                }}>EDIT</button>
                            <button className="button-custom" onClick={deleteAdmin}>delete</button>
                        </div>

                        <p>{user.Username}</p>
                        <p>{user.Email}</p>
                        <p>{user.CellPhone}</p>
                    </div>
                })
            }
            {
                specificAdmin && <>
                    <button className="button-custom" onClick={() => { setSpecificAdmin(false); setShowHeadings(true) }}>&larr; Back</button>
                    <form onSubmit={updateAdmin}>

                        <Form>
                            <Form.Check
                                type="switch"
                                id="update-admin-password-switch"
                                label="update password"
                                onChange={() => setUpdatePassword(!updatePassword)}
                            />
                        </Form>

                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="Name">Admin Name</label>
                            <div className="col-sm-9">
                                <input name="Name" className="form-control" onChange={
                                    (event) => setName(event.target.value)
                                } value={Name}></input>
                            </div>
                        </div>

                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="username">User Name</label>
                            <div className="col-sm-9">
                                <input id="username" name="username" className="form-control" onChange={
                                    (event) => setUsername(event.target.value)
                                } value={Username}></input>
                            </div>
                        </div>

                        {updatePassword
                            &&
                            <div className="form-group row py-2">
                                <label className="col-lg-3 col-form-label" htmlFor="admin-password">Admin Password</label>
                                <div className="col-sm-9">
                                    <input className="form-control" placeholder="password" name="admin-password" onChange={
                                        (event) => setPassword(event.target.value)
                                    } type="password">
                                    </input>
                                </div>
                            </div>
                        }

                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="CellPhone">Admin CellPhone</label>
                            <div className="col-sm-9">
                                <input name="CellPhone" className="form-control" onChange={
                                    (event) => setCellPhone(event.target.value)
                                } value={CellPhone} type="text"></input>
                            </div>
                        </div>

                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="email">Admin Email</label>
                            <div className="col-sm-9">
                                <input name="email"  className="form-control" onChange={
                                    (event) => setEmail(event.target.value)
                                } value={Email}></input>
                            </div>
                        </div>

                        <button className="button-custom">Update</button>
                    </form>
                </>
            }
        </>
    )
}

export default AdminList;