import React, { useState, useContext } from "react"
import { RefreshContext, DisplayContext } from "../context/event-context"
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/style.css";
import axios from "axios";
import Swal from 'sweetalert2'
import { headerConfig } from "../helper/headerConfig";
const AddSite = () => {
    const [refreshKey, setRefreshKey] = useContext(RefreshContext);
    const [Name, setName] = useState("");
    const [Username, setUserName] = useState("");
    const [StreetAddress, setStreetAddress] = useState("");
    const [City, setCity] = useState("");
    const [Province, setProvince] = useState("");
    const [PostalCode, setPostalCode] = useState("");
    const [Directions, setDirection] = useState("");
    const [SpecialNotes, setSpecialNotes] = useState("");
    const [Contact, setContact] = useState("")
    const [Email, setEmail] = useState("");
    const [Phone, setPhone] = useState("");
    const [Password, setPassword] = useState("");
    const [display, setDisplay] = useContext(DisplayContext);
    const [error, setError] = useState({ name: "", username: "", email: "", phone: "", postalcode: "" });

    const handleName = (value) => {
        if ((value.match(/^\s+$/))) {
            setError({ name: "Name cannot be empty" });
        } else {
            setError({ name: null });
            setName(value);
        }
    }
    const handleUserName = (value) => {
        if ((value.match(/^\s+$/))) {
            setError({ username: "User Name cannot be empty" });
        } else {
            setError({ username: null });
            setUserName(value);
        }
    }
    const handleEmail = (value) => {
        let lowercaseEmail = value.toLowerCase();
        if (!(lowercaseEmail.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))) {
            setError({ email: "Email pattern not correct" });
        } else {
            setError({ email: null });
            setEmail(value);
        }
    }

    const handlePostalCode = (value) => {
        if (!(value.match(/^[A-Z]\d[A-Z][ -]?\d[A-Z]\d$/i))) {
            setError({ postalcode: "Postal Code not valid" });
        } else {
            setError({ postalcode: null });
            setPostalCode(value);
        }
    }

    const provinces = [
        "NL",
        "PE",
        "NS",
        "NB",
        "QC",
        "ON",
        "MB",
        "SK",
        "AB",
        "BC",
        "YT",
        "NT",
        "NU"
    ]
    const addSite = async (e) => {
        e.preventDefault();
        if (!error.email && !error.phone && !error.name && !error.username && !error.postalcode) {
            const config = headerConfig();
            await axios.post(`${process.env.GATSBY_API_URL}/addSite`, {
                Name,
                Username, StreetAddress, City, Province, PostalCode, Directions, SpecialNotes, Contact, Email, Phone, Password
            }, config)
                .then((el) => {
                    let response = el.data.data;
                    Swal.fire({
                        title: 'Content Saved!',
                        text: 'Artist Updated Successfully!',
                        icon: 'success',
                        html: `<div class="d-flex flex-column justify-content-start align-items-start">    
                        <p class="text-dark">Username : ${response.Username}</p>
                        <p class="text-dark">Name : ${response.Name}</p>
                        <p class="text-dark">Email : ${response.Email}</p>
                        <p class="text-dark">Contact : ${response.Contact}</p>
                        <p class="text-dark">CellPhone : ${response.Phone}</p>
                        <p class="text-dark">Street Address : ${response.StreetAddress}</p>
                        <p class="text-dark">City : ${response.City}</p>
                        <p class="text-dark">Province : ${response.Province}</p>
                        <p class="text-dark">Special Notes : ${response.SpecialNotes}</p>
                        <p class="text-dark">Directions : ${response.Directions}</p>
                    </div>
                    `
                    })
                    setDisplay([false, false, true, false, false, false, false]);
                    setRefreshKey(refreshKey + 1);
                })
                .catch(err => console.log(err));
        }
    }
    return (
        <div className="h-100">
            <h2 className="d-flex justify-content-center">Add New Site</h2>
            <form onSubmit={addSite}>

                <div className="form-group row py-2">
                    <label className="col-lg-3 col-form-label" htmlFor="name">Name</label>
                    <div className="col-sm-9">
                        <input required onChange={
                            (event) => {
                                handleName(event.target.value)
                            }} className="form-control" type="text" id="name" name="name" placeholder="Name">
                        </input>
                    </div>
                </div>

                <div className="form-group row py-2">
                    <label className="col-lg-3 col-form-label" htmlFor="Username">User Name</label>
                    <div className="col-sm-9">
                        <input required onChange={
                            (event) => {
                                handleUserName(event.target.value)
                            }} className="form-control" type="text" id="Username" name="Username" placeholder="Username">
                        </input>
                    </div>
                </div>

                <div className="form-group row py-2">
                    <label className="col-lg-3 col-form-label" htmlFor="Password">Password</label>
                    <div className="col-sm-9">
                        <input required onChange={
                            (event) => {
                                setPassword(event.target.value)
                            }} className="form-control" type="password" id="Password" name="Password" placeholder="Password">
                        </input>
                    </div>
                </div>

                <div className="form-group row py-2">
                    <label className="col-lg-3 col-form-label" htmlFor="streetAddress">Street Address</label>
                    <div className="col-sm-9">
                        <input required onChange={
                            (event) => {
                                setStreetAddress(event.target.value)
                            }} className="form-control" type="text" id="streetAddress" name="streetAddress" placeholder="Street Address">
                        </input>
                    </div>
                </div>

                <div className="form-group row py-2">
                    <label className="col-lg-3 col-form-label" htmlFor="city">City</label>
                    <div className="col-sm-9">
                        <input required onChange={
                            (event) => {
                                setCity(event.target.value)
                            }} className="form-control" type="text" id="city" name="city" placeholder="City">
                        </input>
                    </div>
                </div>

                <div className="form-group row py-2">
                    <label className="col-lg-3 col-form-label" htmlFor="province">Province</label>
                    <div className="col-sm-9">
                        <select required className="form-control" name="province" id="province" onChange={
                            (event) => setProvince(event.target.value)
                        }>
                            <option value="">---Province---</option>
                            {
                                provinces.sort()?.map((el, i) => {
                                    return <option key={i} className="form-control" value={el}>{el}</option>
                                })
                            }
                        </select>
                    </div>
                </div>

                <div className="form-group row py-2">
                    <label className="col-lg-3 col-form-label" htmlFor="postalCode">Postal Code</label>
                    <div className="col-sm-9">
                        <input required onChange={
                            (event) => handlePostalCode(event.target.value)
                        } className="form-control" type="text" id="postalCode" name="postalCode" placeholder="Postal Code">
                        </input>
                    </div>
                </div>

                <div className="form-group row py-2">
                    <label className="col-lg-3 col-form-label" htmlFor="directions">Directions</label>
                    <div className="col-sm-9">
                        <textarea onChange={
                            (event) => {
                                setDirection(event.target.value)
                            }} className="form-control" type="text-area" id="directions" name="directions" placeholder="Directions">
                        </textarea>
                    </div>
                </div>

                <div className="form-group row py-2">
                    <label className="col-lg-3 col-form-label" htmlFor="specialNotes">Special Notes</label>
                    <div className="col-sm-9">
                        <textarea onChange={
                            (event) => {
                                setSpecialNotes(event.target.value)
                            }} className="form-control" type="text" id="SpecialNotes" name="SpecialNotes" placeholder="Special Notes">
                        </textarea>
                    </div>
                </div>

                <div className="form-group row py-2">
                    <label className="col-lg-3 col-form-label" htmlFor="Contact">Contact Name</label>
                    <div className="col-sm-9">
                        <input onChange={
                            (event) => {
                                setContact(event.target.value)
                            }} className="form-control" type="text" id="Contact" name="Contact" placeholder="Contact Person Name">
                        </input>
                    </div>
                </div>

                <div className="form-group row py-2">
                    <label className="col-lg-3 col-form-label" htmlFor="Email">Email</label>
                    <div className="col-sm-9">
                        <input onChange={
                            (event) => {
                                handleEmail(event.target.value)
                            }} className="form-control" type="email" id="Email" name="Email" placeholder="Email">
                        </input>
                    </div>
                </div>

                <div className="form-group row py-2">
                    <label className="col-lg-3 col-form-label" htmlFor="Phone">Phone</label>
                    <div className="col-sm-9">
                        <input onChange={
                            (event) => {
                                setPhone(event.target.value)
                            }} className="form-control" type="text" id="Phone" name="Phone" placeholder="Phone Number">
                        </input>
                    </div>
                </div>

                {error &&
                    <p className="text-center text-danger">
                        {error.name}
                        {error.username}
                        {error.email}
                        {error.phone}
                        {error.postalcode}
                    </p>
                }
                <button type="submit" className="button-custom" >Submit</button>
            </form>
        </div>
    )
}

export default AddSite;
