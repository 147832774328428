import React, { useState, useContext } from "react"
import FileBase64 from 'react-file-base64';
import { RefreshContext, DisplayContext } from "../context/event-context"
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/style.css";
import axios from "axios";
import Swal from 'sweetalert2'
import { headerConfig } from "../helper/headerConfig";
const AddNewUser = () => {
    const [refreshKey, setRefreshKey] = useContext(RefreshContext);
    const [Name, setName] = useState("");
    const [Username, setUsername] = useState("");
    const [Password, setUserPassword] = useState("");
    const [Email, setEmail] = useState("");
    const [CellPhone, setPhone] = useState("");
    const [userType, setuserType] = useState("");
    const [file, setFile] = useState("");
    const [Bio, setBio] = useState("");
    const [StreetAddress, setStreetAddress] = useState("");
    const [City, setCity] = useState("");
    const [Province, setProvince] = useState("");
    const [PostalCode, setPostalCode] = useState("");
    const [profileImage, setProfileImage] = useState("");

    const [error, setError] = useState({ name: "", username: "", email: "", phone: "", postalcode: "" });
    const [display, setDisplay] = useContext(DisplayContext);
    const handleName = (value) => {
        if ((value.match(/^\s+$/))) {
            setError({ name: "Name cannot be empty" });
        } else {
            setError({ name: null });
            setName(value);
        }
    }
    const handleUserName = (value) => {
        if ((value.match(/^\s+$/))) {
            setError({ username: "User Name cannot be empty" });
        } else {
            setError({ username: null });
            setUsername(value);
        }
    }
    const handleEmail = (value) => {
        let lowercaseEmail = value.toLowerCase();
        if (!(lowercaseEmail.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))) {
            setError({ email: "Email pattern not correct" });
        } else {
            setError({ email: null });
            setEmail(value);
        }
    }
    const handlePhone = (value) => {
        if (!(value.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/))) {
            setError({ phone: "Phone should be 10 digits separated with - or . 123-123-1233" });
        } else {
            setError({ phone: null });
            setPhone(value);
        }
    }
    const handlePostalCode = (value) => {
        setPostalCode(value);
    }


    const userTypes = [
        'Artists',
        'Admins'
    ]
    const provinces = [
        "NL",
        "PE",
        "NS",
        "NB",
        "QC",
        "ON",
        "MB",
        "SK",
        "AB",
        "BC",
        "YT",
        "NT",
        "NU"
    ]
    const addUser = (e) => {
        e.preventDefault();
        if (!error.email && !error.phone && !error.name && !error.username && !error.postalcode) {
            const config = headerConfig();
            axios.post(`${process.env.GATSBY_API_URL}/addUser`, { Name, Username, Password, Email, CellPhone, userType, profileImage, file, Bio, StreetAddress, City, Province, PostalCode }, config)
                .then((el) => {
                    let response = el.data.data;
                    let swalHtml = "";
                    if (userType === 'Artists') {
                        setDisplay([false, true, false, false, false, false, false])
                        swalHtml = `<div class="d-flex flex-column justify-content-start align-items-start">    
                        <img width="150px" src="${response.file}">
                        <p class="text-dark">Username : ${response.Username}</p>
                        <p class="text-dark">Name : ${response.Name}</p>
                        <p class="text-dark">Email : ${response.Email}</p>
                        <p class="text-dark">CellPhone : ${response.CellPhone}</p>
                        <p class="text-dark">Street Address : ${response.StreetAddress}</p>
                        <p class="text-dark">City : ${response.City}</p>
                        <p class="text-dark">Province : ${response.Province}</p>
                    </div>
                    `
                    } else {
                        setDisplay([false, false, false, true, false, false, false]);
                        swalHtml = `<div class="d-flex flex-column justify-content-start align-items-start">    
                        <p class="text-dark">Username :: ${response.Username}</p>
                        <p class="text-dark">Name :: ${response.Name}</p>
                        <p class="text-dark">Email :: ${response.Email}</p>
                        <p class="text-dark">CellPhone  :: ${response.CellPhone}</p>
                    </div>
                    `
                    }
                    Swal.fire({
                        title: 'Content Saved!',
                        text: 'Event Added Successfully!',
                        icon: 'success',
                        html: swalHtml,

                    })
                    setRefreshKey(refreshKey + 1);
                })
                .catch(err => console.log(err));
        }
    }
    return (
        <>
            <h2 className="d-flex justify-content-center">Add New User</h2>
            <form onSubmit={addUser}>
                <div className="form-group row py-2">
                    <label className="col-lg-3 col-form-label" htmlFor="userType">User Type</label>
                    <div className="col-sm-9">
                        <select required className="form-control" name="userType" id="userType" onChange={
                            (event) => setuserType(event.target.value)
                        }>
                            <option value="">---User Type---</option>
                            {
                                userTypes?.map((el, i) => {
                                    return <option key={i} className="form-control" value={el}>{el}</option>
                                })
                            }
                        </select>
                    </div>
                </div>

                <div className="form-group row py-2">
                    <label className="col-lg-3 col-form-label" htmlFor="name">Name</label>
                    <div className="col-sm-9">
                        <input required onChange={
                            (event) => handleName(event.target.value)
                        } className="form-control" type="text" id="name" name="name" placeholder="First & Last Name">
                        </input>
                    </div>
                </div>

                <div className="form-group row py-2">
                    <label className="col-lg-3 col-form-label" htmlFor="userName">User Name</label>
                    <div className="col-sm-9">
                        <input required onChange={
                            (event) => {
                                handleUserName(event.target.value)
                            }} className="form-control" type="text" id="userName" name="userName" placeholder="Username">
                        </input>
                    </div>
                </div>


                <div className="form-group row py-2">
                    <label className="col-lg-3 col-form-label" htmlFor="password">Password</label>
                    <div className="col-sm-9">
                        <input required onChange={
                            (event) => {
                                setUserPassword(event.target.value)
                            }} className="form-control" type="password" id="password" name="password" placeholder="Password">
                        </input>
                    </div>
                </div>

                <div className="form-group row py-2">
                    <label className="col-lg-3 col-form-label" htmlFor="Email">Email</label>
                    <div className="col-sm-9">
                        <input required onChange={
                            (event) => {
                                handleEmail(event.target.value)
                            }} className="form-control" type="email" id="Email" name="Email" placeholder="Email@gmail.com" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                        </input>
                    </div>
                </div>

                <div className="form-group row py-2">
                    <label className="col-lg-3 col-form-label" htmlFor="phone">Phone</label>
                    <div className="col-sm-9">
                        <input onChange={
                            (event) => {
                                handlePhone(event.target.value)
                            }} className="form-control" type="tel" id="phone" name="phone" placeholder="Phone Number" >
                        </input>
                    </div>
                </div>

                {userType === 'Artists' &&
                    <>

                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="streetAddress">
                                Street Address
                            </label>
                            <div className="col-sm-9">
                                <input placeholder="Street Address" className="form-control" name="streetAddress" type="text" onChange={
                                    (event) => setStreetAddress(event.target.value)
                                }></input>
                            </div>
                        </div>

                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="city">City</label>
                            <div className="col-sm-9">
                                <input placeholder="City" className="form-control" name="city" type="text" onChange={
                                    (event) => setCity(event.target.value)
                                }></input>
                            </div>
                        </div>

                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="postalCode">Postal Code</label>
                            <div className="col-sm-9">
                                <input onChange={
                                    (event) => handlePostalCode(event.target.value)
                                } className="form-control" type="text" id="postalCode" name="postalCode" placeholder="V0V V0V" pattern="[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]">
                                </input>
                            </div>
                        </div>

                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="province">Province</label>
                            <div className="col-sm-9">
                                <select className="form-control" name="province" id="province" onChange={
                                    (event) => setProvince(event.target.value)
                                }>
                                    <option value="">---Province---</option>
                                    {
                                        provinces.sort().map((el, i) => {
                                            return <option key={i} className="form-control" value={el}>{el}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label">Artist Profile Image</label>
                            <div className="col-sm-9">
                                <FileBase64
                                    required={true}
                                    type="file"
                                    multiple={false}
                                    onDone={
                                        ({ name, base64 }) => { setProfileImage(name); setFile(base64) }}
                                />
                            </div>
                        </div>

                        <div className="form-group row py-2">
                            <label htmlFor="artistBio" className="col-lg-3 col-form-label">Artist Bio</label>
                            <div className="col-sm-9">
                                <textarea name="artistBio" required onChange={
                                    (event) => {
                                        setBio(event.target.value)
                                    }} className="form-control"
                                    placeholder="Bio"></textarea>
                            </div>
                        </div>

                    </>
                }
                {error &&
                    <p className="text-center text-danger">
                        {error.name}
                        {error.username}
                        {error.email}
                        {error.phone}
                        {error.postalcode}
                    </p>
                }
                <button type="submit" className="button-custom" >Submit</button>
            </form>
        </>
    )
}

export default AddNewUser;
