import React, { useState, useEffect } from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/style.css";
import AddEvent from "../components/add-event";
import AddNewUser from "../components/add-user";
import AddSite from "../components/add-site";
import axios from "axios";
import Header from "../components/header";
import Footer from "../components/footer";
import Login from "../components/login";

import {
    UserLoginContext,
    DarkThemeContext,
    LastEditDataContext,
    LoginContext,
    UserTypeContext,
    RefreshContext,
    DisplayContext,
} from "../context/event-context";

import EventList from "../components/event-list";
import ArtistList from "../components/artist-list";
import SiteList from "../components/site-list";
import AdminList from "../components/admin-list";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { headerConfig } from "../helper/headerConfig";

export default function Index() {

    const [showAddEvent, setShowAddEvent] = useState(false);
    const [showAddUser, setShowAddUser] = useState(false);
    const [showAddSite, setShowAddSite] = useState(false);
    const [showArtists, setShowArtists] = useState(false);
    const [showAdmins, setShowAdmins] = useState(false);
    const [showSites, setShowSites] = useState(false);
    const [showEvents, setShowEvents] = useState(false);

    const [showHeading, setShowHeading] = useState(true);
    const [showSiteHeading, setShowSiteHeading] = useState(true);

    const [artists, setArtists] = useState();
    const [admins, setAdmins] = useState();
    const [sites, setSites] = useState();
    const [userType, setUserType] = useState("");
    const [login, setLogin] = useState(false);
    const [userState, setUserState] = useState();
    const [refreshKey, setRefreshKey] = useState(0);
    const [eventData, setEventData] = useState([]);
    const [scroll, setScroll] = useState(0);
    const [Province, setProvince] = useState("");
    const [performers, setPerformers] = useState();

    const [siteName, setSiteName] = useState("");

    const [artistFullName, setArtistFullName] = useState();
    const [eventSiteName, setEventSiteName] = useState("");
    const [dark, setDark] = useState(true);
    const [lastEditData, setLastEditData] = useState(null);
    const [display, setDisplay] = useState([true, false, false, false, false, false, false]);
    const [showList, setShowList] = useState(true);

    const provinces = [
        "NL",
        "PE",
        "NS",
        "NB",
        "QC",
        "ON",
        "MB",
        "SK",
        "AB",
        "BC",
        "YT",
        "NT",
        "NU"
    ];

    const fetchData = async () => {
        try {
            await axios.get(`${process.env.GATSBY_API_URL}`)
                .then(el => {
                    const sorted_by_date = el.data.data.sort((a, b) => {
                        let aDate = new Date(a.date)
                        let bDate = new Date(b.date);
                        aDate.setUTCHours(parseInt(a.time.split(':')[0]));
                        bDate.setUTCHours(parseInt(b.time.split(':')[0]));
                        aDate.setUTCMinutes(parseInt(a.time.split(':')[1]));
                        bDate.setUTCMinutes(parseInt(b.time.split(':')[1]));

                        return aDate - bDate;
                    });

                    setEventData(sorted_by_date)
                })
        } catch (err) {
            console.log(err);
        }
    };

    const findArtistFullName = (artists) => {
        artists.forEach((el) => {
            if (el.Username === userState) {
                setArtistFullName(el.Name);
            }
        });
    };

    const findEventSiteName = (sites) => {
        sites.forEach((el) => {
            if (el.Username === userState) {
                setEventSiteName(el.Name);
            }
        })
    };

    const getArtists = async () => {
        await axios.get(`${process.env.GATSBY_API_URL}/getAllArtists`)
            .then((data) => {
                setArtists(data.data.data, findArtistFullName(data.data.data))
            }
            );

    };

    const getAdmins = async () => {
        const config = headerConfig();
        await axios.post(`${process.env.GATSBY_API_URL}/getUsers`, { type: 'Admins' }, config)
            .then((data) => setAdmins(data.data.data));
    };

    const getAllSites = async () => {
        setShowAddUser(false); setShowAddEvent(false); setShowAddSite(false); setShowArtists(false); setShowAdmins(false);
        await axios.get(`${process.env.GATSBY_API_URL}/getSites`)
            .then((data) => {
                setSites(data.data.data, findEventSiteName(data.data.data));
            });
    };

    // sets the display for admin view on button clicks
    const setViewState = (show1, show2, show3, show4, show5, show6, show7) => {
        [show1, show2, show3, show4, show5, show6, show7]?.map((val, index) => {
            let currentButton = document.querySelector(`.navbar-nav >  button:nth-child(${index + 1})`);
            currentButton
                &&
                currentButton.classList.contains("active")
                &&
                currentButton.classList.remove("active");
            if (val) {
                currentButton && currentButton.classList.add("active");
            }
        })
        setShowEvents(show1)
        setShowArtists(show2);
        setShowSites(show3);
        setShowAdmins(show4);
        setShowAddEvent(show5);
        setShowAddUser(show6);
        setShowAddSite(show7);
        // setScroll(0);
        // localStorage.setItem("scroll", 0);
    };

    const checkIfLoggedIn = async (token) => {
        try {
            const config = headerConfig();
            const response = await axios.post(`${process.env.GATSBY_API_URL}/verifyToken`, {}, config);
            return response;
        } catch (err) {
            console.log(err);
        }
    };

    const showHeadings = () => {
        setShowHeading(true);
    };

    const hideHeadings = () => {
        setShowHeading(false);
    }
    function checkUserData() {
        const token = localStorage.getItem('token');
        if (token) {
            checkIfLoggedIn(token).then((res) => {
                if (res) {
                    const tokenIsValid = res.data;
                    if (tokenIsValid.isUserValid) {
                        window.history.pushState(null, null, window.location.href);
                        window.onpopstate = function () {
                            window.history.go(1);
                        };
                        setLogin(true);
                        setUserType(tokenIsValid.userType);
                        setUserState(tokenIsValid.userName);
                        fetchData();
                        getArtists();
                        getAllSites();
                        getAdmins();
                        setViewState(...display);
                    }
                }
            });
        }
    };

    const resetSelects = (event) => {
        Array.from(document.querySelectorAll("select"))?.map((el) => {
            if (el.id !== event.target.id) {
                el.selectedIndex = 0;
            }
        })
    }


    useEffect(() => {
        login && checkUserData();
    }, [login, userType, userState, refreshKey]);

    useEffect(() => {
        let theme = localStorage.getItem('theme');
        if (theme && theme === 'dark') {
            setDark(false);
            dark && document.body.classList.add('dark');
        } else if (theme && theme === 'light') {
            document.body.classList.contains('dark')
                && document.body.classList.remove('dark');
            setDark(true);
        }
    }, [dark])
    return (
        <UserLoginContext.Provider value={[userState, setUserState]}>
            <UserTypeContext.Provider value={[userType, setUserType]}>
                <LastEditDataContext.Provider value={[lastEditData, setLastEditData]}>
                    <DisplayContext.Provider value={[display, setDisplay]}>
                        <DarkThemeContext.Provider value={[dark, setDark]}>
                            <LoginContext.Provider value={[login, setLogin]}>
                                <RefreshContext.Provider value={[refreshKey, setRefreshKey]}>
                                    {
                                        (login
                                            && userType === 'Admins'
                                            && eventData && artists && sites) ? <>
                                            <Header />
                                            <div className="main-content container mx-auto" >
                                                <Navbar expand="lg">
                                                    <Container>
                                                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                                        <Navbar.Collapse id="basic-navbar-nav">
                                                            <Nav className="mx-auto">
                                                                <button className="button-custom" onClick={() => {
                                                                    setViewState(true, false, false, false, false, false, false);
                                                                    localStorage.setItem('scroll', 0);
                                                                    setShowList(true);
                                                                }}>Events</button>
                                                                <button className="button-custom" onClick={() => {
                                                                    setViewState(false, true, false, false, false, false, false);
                                                                    localStorage.setItem('scroll', 0);
                                                                    setShowList(true);
                                                                }}>Artists</button>
                                                                <button className="button-custom" onClick={() => {
                                                                    setViewState(false, false, true, false, false, false, false);
                                                                    localStorage.setItem('scroll', 0);
                                                                    setShowList(true);
                                                                }}>Sites</button>
                                                                <button className="button-custom" onClick={() => {
                                                                    setViewState(false, false, false, true, false, false, false);
                                                                    localStorage.setItem('scroll', 0);
                                                                    setShowList(true);
                                                                }}>Admins</button>
                                                                <button className="button-custom" onClick={() => {
                                                                    setViewState(false, false, false, false, true, false, false);
                                                                    localStorage.setItem('scroll', 0);
                                                                    setShowList(true);
                                                                }}>Add Event</button>

                                                                <button className="button-custom" onClick={() => {
                                                                    setViewState(false, false, false, false, false, true, false);
                                                                    localStorage.setItem('scroll', 0);
                                                                    setShowList(true);
                                                                }}>Add User</button>
                                                                <button className="button-custom" onClick={() => {
                                                                    setViewState(false, false, false, false, false, false, true);
                                                                    localStorage.setItem('scroll', 0);
                                                                    setShowList(true);
                                                                }}>Add Site</button>

                                                            </Nav>
                                                        </Navbar.Collapse>
                                                    </Container>
                                                </Navbar>

                                                <div className="col">
                                                    {
                                                        showArtists
                                                        && (artists
                                                            && artists.length
                                                            && showHeading
                                                            && userType === 'Admins')
                                                        && <>
                                                            <p>Filter Province</p>

                                                            <select
                                                                className="form-control" name="province" id="province" onChange={(event) => {
                                                                    setProvince(event.target.value)
                                                                    setPerformers("");
                                                                    resetSelects(event);
                                                                }
                                                                }>
                                                                <option value="">---ANY---</option>
                                                                {provinces.sort().map((el, i) => {
                                                                    return <option key={i} className="form-control" value={el}>{el}</option>
                                                                })
                                                                }
                                                            </select>

                                                            <p>Filter Artists</p>
                                                            <select className="form-control" value={performers} name="artists" id="artists" onChange={(event) => {
                                                                setPerformers(event.target.value)
                                                                setProvince("")
                                                                resetSelects(event);
                                                            }
                                                            }>
                                                                <option value="">---ANY---</option>
                                                                {artists.sort((a, b) => a.Name.localeCompare(b.Name)).map((el, i) => {
                                                                    if (el.Name) {
                                                                        return <option key={i} className="form-control" value={el.Name}>{el.Name}</option>
                                                                    }
                                                                })
                                                                }
                                                            </select>
                                                        </>
                                                    }
                                                    {showArtists && artists && artists.length && showHeading && userType === 'Admins' && <div className="artist-heading mx-auto">
                                                        <p>Action</p>
                                                        <p>Name</p>
                                                        <p>User Name</p>
                                                        <p>Email</p>
                                                        <p>Phone</p>
                                                        <p>Address</p>

                                                    </div>}

                                                    {
                                                        showArtists
                                                        && (artists
                                                            && artists.length
                                                            && userType === 'Admins')
                                                        && <ArtistList artists={artists} performers={performers} Province={Province} userType={userType} showHeadings={showHeadings} hideHeadings={hideHeadings} showList={showList} />
                                                    }
                                                    <div>
                                                        {
                                                            showAdmins
                                                            && (admins
                                                                && admins.length)
                                                            && <AdminList admins={admins} />
                                                        }
                                                    </div>

                                                    {
                                                        showSites
                                                        && (sites
                                                            && sites.length
                                                            && showSiteHeading
                                                            && userType === 'Admins')
                                                        && <>
                                                            <p>Filter Province</p>
                                                            <select className="form-control" name="province" id="province" onChange={(event) => {
                                                                setProvince(event.target.value)
                                                                setSiteName("")
                                                                resetSelects(event);
                                                            }}>
                                                                <option value="">---ANY---</option>
                                                                {provinces.sort().map((el, i) => {
                                                                    return <option key={i} className="form-control" value={el}>{el}</option>
                                                                })
                                                                }
                                                            </select>

                                                            <p>Filter Sites</p>
                                                            <select className="form-control" value={siteName} name="siteName" id="siteName" onChange={(event) => {
                                                                setSiteName(event.target.value)
                                                                setProvince("")
                                                                resetSelects(event);
                                                            }

                                                            }>
                                                                <option value="">---ANY---</option>
                                                                {sites.sort((a, b) => a.Name.localeCompare(b.Name))?.map((el, i) => {
                                                                    if (el.Name) {
                                                                        return <option key={i} className="form-control" value={el.Name}>{el.Name}</option>
                                                                    }
                                                                })
                                                                }
                                                            </select>
                                                        </>
                                                    }
                                                    {
                                                        showSites
                                                        && sites
                                                        && sites.length
                                                        && showSiteHeading
                                                        && <div className="artist-heading mx-auto">
                                                            <p>Action</p>
                                                            <p>Name</p>
                                                            <p>User Name</p>
                                                            <p>Street Address</p>
                                                            <p>City</p>
                                                            <p>Province</p>
                                                        </div>
                                                    }
                                                    <div className="sites-display">
                                                        {
                                                            showSites
                                                            && sites
                                                            && sites.length
                                                            && <SiteList sites={sites} showheadings={setShowSiteHeading} province={Province} siteName={siteName} showList={showList} />
                                                        }
                                                    </div>

                                                    {
                                                        (showAddEvent)
                                                        &&
                                                        <AddEvent sites={sites} artists={artists} />
                                                    }
                                                    {
                                                        (showAddUser)
                                                        &&
                                                        <AddNewUser />
                                                    }
                                                    {
                                                        (showAddSite)
                                                        &&
                                                        <AddSite />
                                                    }
                                                    {

                                                        ((showEvents) && (
                                                            eventData
                                                            && (eventData.length))
                                                            && <EventList eventData={eventData} artists={artists} sites={sites} showList={showList} />)
                                                    }
                                                </div>
                                            </div >
                                            <Footer />
                                        </> : (login && userType === 'Admins' && <div className="d-flex justify-content-center align-items-center loader-container">
                                            <div className="loading">
                                                <span className='dot2'>
                                                </span>
                                                <span className='dot'>
                                                </span>
                                                <span className='dot'>
                                                </span>
                                            </div>
                                        </div>)
                                    }
                                    {(login
                                        && userType === 'Artists'
                                        && eventData
                                        && eventData.length
                                        && artistFullName)
                                        ? <>
                                            <Header />
                                            <div className="main-content">
                                                <EventList eventData={eventData} artistFullName={artistFullName} artists={artists} sites={sites}></EventList>

                                            </div>
                                            <Footer />
                                        </>
                                        : (login
                                            && userType === 'Artists'
                                            && !artistFullName
                                            && <div className="d-flex justify-content-center align-items-center loader-container">
                                                <div className="loading">
                                                    <span className='dot2'>
                                                    </span>
                                                    <span className='dot'>
                                                    </span>
                                                    <span className='dot'>
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        (login
                                            && userType === 'Sites'
                                            && eventData
                                            && eventData.length
                                            && eventSiteName)
                                            ? <>
                                                <Header />
                                                <div className="main-content">
                                                    <EventList eventData={eventData} eventSiteName={eventSiteName} artists={artists} sites={sites}></EventList>
                                                </div>
                                                <Footer />
                                            </> : (login
                                                && userType === 'Sites'
                                                && !eventSiteName
                                                && <div className="d-flex justify-content-center align-items-center loader-container">
                                                    <div className="loading">
                                                        <span className='dot2'>
                                                        </span>
                                                        <span className='dot'>
                                                        </span>
                                                        <span className='dot'>
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                    }

                                    {
                                        !login
                                        &&
                                        <div>
                                            <Login />
                                        </div>
                                    }
                                </RefreshContext.Provider>
                            </LoginContext.Provider>
                        </DarkThemeContext.Provider>
                    </DisplayContext.Provider>
                </LastEditDataContext.Provider>
            </UserTypeContext.Provider>
        </UserLoginContext.Provider >
    )
};
