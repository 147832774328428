import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2'
import { Form } from "react-bootstrap";
import FileBase64 from 'react-file-base64';
import { RefreshContext, DisplayContext } from "../context/event-context"
import { headerConfig } from "../helper/headerConfig";
import ScrollToTop from "react-scroll-to-top";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
    faTrashCan,
    faPenToSquare,
    faBackward,
    faEye
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ArtistList = ({ artists, performers, Province, userType, hideHeadings, showHeadings }) => {
    const [id, setId] = useState();
    const [specificArtist, setSpecificArtist] = useState(false);
    const [StreetAddress, setStreetAddress] = useState();
    const [ArtistProvince, setArtistProvince] = useState();
    const [Name, setName] = useState();
    const [Username, setUsername] = useState();
    const [Email, setEmail] = useState();
    const [Bio, setBio] = useState();
    const [City, setCity] = useState();
    const [CellPhone, setCellPhone] = useState();
    const [Password, setPassword] = useState();
    const [updatePassword, setUpdatePassword] = useState(false);
    const [updateProfile, setUpdateProfile] = useState(false);
    const [PostalCode, setPostalCode] = useState("");
    // const [profileImage, setProfileImage] = useState
    const [file, setFile] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [viewArtist, setViewArtist] = useState(true);
    const [refreshKey, setRefreshKey] = useContext(RefreshContext);
    const [display, setDisplay] = useContext(DisplayContext);
    const provinces = [
        "NL",
        "PE",
        "NS",
        "NB",
        "QC",
        "ON",
        "MB",
        "SK",
        "AB",
        "BC",
        "YT",
        "NT",
        "NU"
    ];

    const updateArtist = async (e) => {
        e.preventDefault();
        let updatedData = { _id: id, StreetAddress: StreetAddress, Province: ArtistProvince, Name: Name, Username: Username, Email: Email, Bio: Bio, City: City, CellPhone: CellPhone, PostalCode: PostalCode };
        if (updatePassword) {
            updatedData["Password"] = Password
        }
        if (updateProfile) {
            updatedData["profileImage"] = profileImage
            updatedData["file"] = file
        }
        const config = headerConfig();
        axios.put(`${process.env.GATSBY_API_URL}/updateArtist`, updatedData, config).then((el) => {
            let response = el.data.data
            Swal.fire({
                title: 'Content Saved!',
                text: 'Artist Updated Successfully!',
                icon: 'success',
                html: `<div class="d-flex flex-column justify-content-start align-items-start">    
                <img width="150px" src="${file}">
                <p class="text-dark">Username : ${response.Username}</p>
                <p class="text-dark">Name : ${response.Name}</p>
                <p class="text-dark">Email : ${response.Email}</p>
                <p class="text-dark">CellPhone  : ${response.CellPhone}</p>
                <p class="text-dark">Street Address  : ${response.StreetAddress}</p>
                <p class="text-dark">City  : ${response.City}</p>
                <p class="text-dark">Province  : ${response.Province}</p>
            </div>
            `
            })
            setDisplay([false, true, false, false, false, false, false]);
            setRefreshKey(refreshKey + 1);
        }).catch(err => alert(err));
    }
    const deleteUser = () => {
        Swal.fire({
            title: 'Delete this user?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const config = headerConfig();
                axios.post(`${process.env.GATSBY_API_URL}/deleteArtist`, { id }, config).then(
                    (data) => {
                        Swal.fire(
                            'Content Saved!',
                            'Artist Deleted Successfully!',
                            'success'
                        );
                        setDisplay([false, true, false, false, false, false, false]);
                        setRefreshKey(refreshKey + 1);
                    }).catch(err => console.log(err));
            }
        });
    }

    useEffect(() => {
        if (!specificArtist) {
            showHeadings();
            if (localStorage.getItem('scroll')) {
                window.scrollTo(0, parseInt(localStorage.getItem('scroll')));
            }
        }

    }, [])
    useEffect(() => {

        async function getProfileImage() {
            if (viewArtist) {
                await axios.post(`${process.env.GATSBY_API_URL}/getProfileImage`, { _id: id })
                    .then((data) => {
                        setFile(data.data.data);
                    })
            }
        }
        getProfileImage();

    }, [specificArtist, viewArtist]);

    useEffect(() => {
        if (!specificArtist) {
            if (localStorage.getItem('scroll')) {
                window.scrollTo(0, parseInt(localStorage.getItem('scroll')));
            }
        }
    }, [specificArtist])

    return (
        <>
            {
                !specificArtist && artists && artists.length &&
                artists.sort((a, b) => a.Name.localeCompare(b.Name)).map((user, i) => {
                    if (user.Name) {
                        if (performers && user.Name === performers) {
                            return (
                                <div key={i}>
                                    <div className="artistList mb-3 mb-lg-0 mx-auto">
                                        <div className="d-flex flex-column">
                                            <button className="button-custom d-flex justify-content-center" onClick={
                                                () => {
                                                    setId(user._id);
                                                    setName(user.Name);
                                                    setBio(user.Bio);
                                                    setEmail(user.Email);
                                                    setStreetAddress(user.StreetAddress);
                                                    setUsername(user.Username);
                                                    setCellPhone(user.CellPhone);
                                                    setCity(user.City);
                                                    setArtistProvince(user.Province);
                                                    setFile(user.file);
                                                    setPostalCode(user.PostalCode);
                                                    hideHeadings();
                                                    setSpecificArtist(!specificArtist);
                                                    localStorage.setItem('scroll', window.scrollY);
                                                }
                                            }>View
                                                <FontAwesomeIcon className="px-2" icon={faEye} />
                                            </button>
                                        </div>
                                        <p className={`${user.Name && "border-lg-none border-bottom"}`}>{user.Name}</p>
                                        <p className={`${user.Username && "border-lg-none border-bottom"}`}>{user.Username}</p>
                                        <p className={`${user.Email && "border-lg-none border-bottom"}`}>{user.Email}</p>
                                        <p className={`${user.CellPhone && "border-lg-none border-bottom"}`}>{user.CellPhone}</p>
                                        <p className={`${user.StreetAddress && "border-lg-none border-bottom"}`}>{user.StreetAddress && <>{user.StreetAddress}, </>}{user.City && <>{user.City}, </>}{user.Province}</p>
                                    </div>
                                </div>
                            )
                        }
                        else if (Province && user.Province === Province) {
                            return (
                                <div key={i}>
                                    <div className="artistList mb-3 mb-lg-0 mx-auto">
                                        <div className="d-flex flex-column">
                                            <button className="button-custom d-flex justify-content-center" onClick={() => {
                                                setId(user._id);
                                                setName(user.Name);
                                                setBio(user.Bio);
                                                setEmail(user.Email);
                                                setStreetAddress(user.StreetAddress);
                                                setUsername(user.Username);
                                                setCellPhone(user.CellPhone);
                                                setCity(user.City);
                                                setArtistProvince(user.Province);
                                                setFile(user.file);
                                                setPostalCode(user.PostalCode);
                                                hideHeadings();
                                                setSpecificArtist(!specificArtist);
                                                localStorage.setItem('scroll', window.scrollY);
                                            }}>View
                                                <FontAwesomeIcon className="px-2" icon={faEye} />
                                            </button>
                                        </div>
                                        <p className={`${user.Name && "border-lg-none border-bottom"}`}>{user.Name}</p>
                                        <p className={`${user.Username && "border-lg-none border-bottom"}`}>{user.Username}</p>
                                        <p className={`${user.Email && "border-lg-none border-bottom"}`}>{user.Email}</p>
                                        <p className={`${user.CellPhone && "border-lg-none border-bottom"}`}>{user.CellPhone}</p>
                                        <p className={`${user.StreetAddress && "border-lg-none border-bottom"}`}>{user.StreetAddress && <>{user.StreetAddress}, </>}{user.City && <>{user.City}, </>}{user.Province}</p>
                                    </div>
                                </div>
                            )
                        }
                        else if (!performers && !Province) {
                            return (
                                <div key={i}>
                                    <div className="artistList mb-3 mb-lg-0 mx-auto">
                                        <div className="col-3 d-flex justify-content-center">
                                            <button className="button-custom" onClick={() => {
                                                setId(user._id);
                                                setName(user.Name);
                                                setBio(user.Bio);
                                                setEmail(user.Email);
                                                setStreetAddress(user.StreetAddress);
                                                setUsername(user.Username);
                                                setCellPhone(user.CellPhone);
                                                setCity(user.City);
                                                setArtistProvince(user.Province);
                                                setFile(user.file);
                                                setPostalCode(user.PostalCode);
                                                hideHeadings();
                                                setSpecificArtist(!specificArtist);
                                                localStorage.setItem('scroll', window.scrollY);
                                            }}>View
                                                <FontAwesomeIcon className="px-2" icon={faEye} />
                                            </button>
                                        </div>
                                        <p className={`${user.Name && "border-lg-none border-bottom"}`}>{user.Name}</p>
                                        <p className={`${user.Username && "border-lg-none border-bottom"}`}>{user.Username}</p>
                                        <p className={`${user.Email && "border-lg-none border-bottom"}`}>{user.Email}</p>
                                        <p className={`${user.CellPhone && "border-lg-none border-bottom"}`}>{user.CellPhone}</p>
                                        <p className={`${user.StreetAddress && "border-lg-none border-bottom"}`}>{user.StreetAddress && <>{user.StreetAddress}, </>}{user.City && <>{user.City}, </>}{user.Province}</p>
                                    </div>
                                </div>
                            )
                        }
                    }
                })
            }
            {
                specificArtist && !viewArtist && <>
                    <section className='d-flex justify-content-between flex-wrap flex-lg-nowrap'>
                        <button className="button-custom" onClick={() => { setSpecificArtist(false); showHeadings() }}>
                            <FontAwesomeIcon className='px-2' icon={faBackward} />
                            Back
                        </button>

                        <div className="d-flex justify-content-center container">
                            <Form>
                                <Form.Check
                                    type="switch"
                                    id="update-artist-password-switch"
                                    label="update password"
                                    onChange={() => setUpdatePassword(!updatePassword)}
                                />
                            </Form>
                            <Form>
                                <Form.Check
                                    type="switch"
                                    id="update-artist-profile-switch"
                                    label="update profile picture"
                                    onChange={() => setUpdateProfile(!updateProfile)}
                                />
                            </Form>
                        </div>
                        <div className="d-flex">
                            {
                                (!viewArtist) ?

                                    <button className="button-custom" onClick={() => setViewArtist(true)} >View
                                        <FontAwesomeIcon className="px-2" icon={faEye} />
                                    </button>
                                    : <button className="button-custom" onClick={() => setViewArtist(false)} >Edit
                                        <FontAwesomeIcon className='px-2' icon={faPenToSquare} />
                                    </button>
                            }

                            <button className="button-custom bg-danger d-flex justify-content-center" onClick={deleteUser}>Delete
                                <FontAwesomeIcon className='px-2' icon={faTrashCan} />
                            </button>

                        </div>
                    </section>

                    <form onSubmit={updateArtist}>
                        <div className="d-flex">
                            <img width="150px" height="auto" src={file}></img>
                        </div>
                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="Name">Artist Name</label>
                            <div className="col-sm-9">
                                <input required name="Name" className="form-control" onChange={
                                    (event) => setName(event.target.value)
                                } value={Name}></input>
                            </div>
                        </div>

                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="username">User Name</label>
                            <div className="col-sm-9">
                                <input required id="username" name="username" className="form-control" onChange={
                                    (event) => setUsername(event.target.value)
                                } value={Username}></input>
                            </div>
                        </div>
                        {updatePassword
                            &&
                            <div className="form-group row py-2">
                                <label className="col-lg-3 col-form-label" htmlFor="admin-password">Artist Password</label>
                                <div className="col-sm-9">
                                    <input className="form-control" placeholder="password" name="admin-password" onChange={
                                        (event) => setPassword(event.target.value)
                                    } type="password">
                                    </input>
                                </div>
                            </div>
                        }
                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="CellPhone">Phone</label>
                            <div className="col-sm-9">
                                <input name="CellPhone" className="form-control" onChange={
                                    (event) => setCellPhone(event.target.value)
                                } value={CellPhone} type="text"></input>
                            </div>
                        </div>
                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="Bio">Bio</label>
                            <div className="col-sm-9">
                                <textarea name="Bio" placeholder="Bio" className="form-control" onChange={
                                    (event) => setBio(event.target.value)
                                } value={Bio}></textarea>
                            </div>
                        </div>
                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="email">Email</label>
                            <div className="col-sm-9">
                                <input name="email" type="email" className="form-control" onChange={
                                    (event) => setEmail(event.target.value)
                                } value={Email}></input>
                            </div>
                        </div>
                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="street-address"> Street Address</label>
                            <div className="col-sm-9">
                                <input id="street-address" name="street-address" className="form-control" onChange={
                                    (event) => setStreetAddress(event.target.value)
                                } value={StreetAddress}></input>
                            </div>
                        </div>
                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="city">City</label>
                            <div className="col-sm-9">
                                <input name="city" className="form-control" onChange={
                                    (event) => setCity(event.target.value)
                                } value={City}></input>
                            </div>
                        </div>
                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="province">Province</label>
                            <div className="col-sm-9">
                                <select className="form-control" name="province" id="province" value={ArtistProvince} onChange={(event) => setArtistProvince(event.target.value)}>
                                    <option value="">---Province---</option>
                                    {
                                        provinces.sort()?.map((el, i) => {
                                            if (el) {
                                                return <option key={i} className="form-control" value={el}>{el}</option>
                                            }
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="postalCode">Postal Code</label>
                            <div className="col-sm-9">
                                <input onChange={
                                    (event) => setPostalCode(event.target.value)
                                } className="form-control" value={PostalCode} type="text" id="postalCode" name="postalCode" placeholder="V0V V0V" pattern="[A-Za-z][0-9][A-Za-z] [0-9][A-Za-z][0-9]">
                                </input>
                            </div>
                        </div>
                        {
                            updateProfile &&
                            <div className="form-group row py-2">
                                <label className="col-lg-3 col-form-label">Artist Profile Image</label>
                                <div className="col-sm-9">
                                    <FileBase64
                                        type="file"
                                        multiple={false}
                                        value={file}
                                        onDone={
                                            ({ name, base64 }) => { setProfileImage(name); setFile(base64) }}
                                    />
                                </div>
                            </div>
                        }
                        <button className="button-custom">Update</button>
                    </form>
                </>
            }
            {
                specificArtist && viewArtist && <>
                    <section className='d-flex py-3 justify-content-between'>
                        <button className="button-custom" onClick={() => { setSpecificArtist(false); showHeadings() }}>
                            <FontAwesomeIcon className='px-2' icon={faBackward} />
                            Back</button>
                        <div className="d-flex">
                            {
                                (!viewArtist) ?

                                    <button className="button-custom" onClick={() => setViewArtist(true)} >View
                                        <FontAwesomeIcon className="px-2" icon={faEye} />
                                    </button>
                                    : <button className="button-custom" onClick={() => setViewArtist(false)} >Edit
                                        <FontAwesomeIcon className='px-2' icon={faPenToSquare} />
                                    </button>
                            }

                            <button className="button-custom bg-danger d-flex justify-content-center" onClick={deleteUser}>
                                Delete
                                <FontAwesomeIcon className='px-2' icon={faTrashCan} />
                            </button>

                        </div>
                    </section>
                    <h2>
                        Artist Information
                    </h2>
                    <hr />
                    <h2>Bio</h2>
                    <hr></hr>
                    <div className="d-flex flex-column container">
                        <div className="d-flex gap-2 flex-column flex-lg-row mb-4">
                            <div className='col-lg-2'>
                                <LazyLoadImage style={{ float: "left", width: "100%", maxWidth: "300px" }} className='p-3' src={file} />
                            </div>
                            <div className="col-lg-7 p-3">
                                <p><strong>{Name}</strong> </p>
                                <br></br>
                                <p>{Bio}</p>
                            </div>
                        </div>
                        <h2 className="mb-0">
                            Contact
                        </h2>
                        <hr className="mt-0"></hr>
                        <p><strong>Email: </strong>{Email}</p>
                        <p><strong>Phone:</strong> {CellPhone}</p>
                        <br></br>
                        <h2 className="mb-0">
                            Address
                        </h2>
                        <hr className="mt-0">
                        </hr>
                        <p>{StreetAddress}</p>
                        <p>{City}</p>
                        <p>{ArtistProvince}</p>
                        <p>{PostalCode}</p>
                        <br></br>
                    </div>
                </>
            }
            <ScrollToTop smooth />

        </>
    )
}

export default ArtistList;