import React, { useState, useContext, useEffect } from "react";
import ViewEvent from "../components/view-event";
import Event from "../components/event";
import { UserTypeContext, UserLoginContext } from "../context/event-context";
import ScrollToTop from "react-scroll-to-top";
import axios from "axios";

const EventList = ({
  eventData,
  artists,
  sites,
  artistFullName,
  eventSiteName,
}) => {
  const [specificEvent, setSpecificEvent] = useState(false);
  const [showHeading, setShowHeading] = useState(true);
  const [userType, setUserType] = useContext(UserTypeContext);
  const [userState, setUserState] = useContext(UserLoginContext);
  const [Province, setProvince] = useState("");
  const [performers, setPerformers] = useState();
  const [selectedSite, setSelectedSite] = useState();
  const [haveEvents, setHaveEvents] = useState(false);

  const selectEvent = (data) => {
    setSpecificEvent(data);
  };
  const viewAllEvents = () => {
    setShowHeading(true);
    setSpecificEvent(false);
  };
  const hideHeading = () => {
    setShowHeading(false);
  };
  const provinces = [
    "NL",
    "PE",
    "NS",
    "NB",
    "QC",
    "ON",
    "MB",
    "SK",
    "AB",
    "BC",
    "YT",
    "NT",
    "NU",
  ];

  const resetSelects = (event) => {
    Array.from(document.querySelectorAll("select"))?.map((el) => {
      if (el.id !== event.target.id) {
        el.selectedIndex = 0;
      }
    });
  };

  useEffect(() => {
    if (!specificEvent && localStorage.getItem("scroll")) {
      window.scrollTo(0, localStorage.getItem("scroll"));
    }
  }, [specificEvent]);
  return (
    <UserLoginContext.Provider value={[userState, setUserState]}>
      <UserTypeContext.Provider value={[userType, setUserType]}>
        <div className="col">
          {eventData && sites && userType === "Admins" && !specificEvent && (
            <>
              <p>Filter sites</p>
              <select
                className="form-control"
                name="sites"
                id="sites"
                onChange={(event) => {
                  setSelectedSite(event.target.value);
                  setProvince("");
                  setPerformers("");
                  resetSelects(event);
                }}
              >
                <option value="">---ANY---</option>
                {sites
                  .sort((a, b) => a.Name.localeCompare(b.Name))
                  ?.map((el, i) => {
                    if (el.Name) {
                      return (
                        <option
                          key={i}
                          className="form-control"
                          value={el.Name}
                        >
                          {el.Name}
                        </option>
                      );
                    }
                  })}
              </select>
            </>
          )}

          {eventData && userType === "Admins" && !specificEvent && (
            <>
              <p>Filter Province</p>
              <select
                className="form-control"
                name="province"
                id="province"
                onChange={(event) => {
                  setProvince(event.target.value);
                  setPerformers("");
                  setSelectedSite("");
                  resetSelects(event);
                }}
              >
                <option value="">---ANY---</option>
                {provinces.sort()?.map((el, i) => {
                  return (
                    <option key={i} className="form-control" value={el}>
                      {el}
                    </option>
                  );
                })}
              </select>
            </>
          )}
          {artists && userType === "Admins" && !specificEvent && (
            <>
              <p>Filter Artists</p>
              <select
                className="form-control"
                value={performers}
                name="artists"
                id="artists"
                onChange={(event) => {
                  setPerformers(event.target.value);
                  setProvince("");
                  setSelectedSite("");
                  resetSelects(event);
                }}
              >
                <option value="">---ANY---</option>
                {artists
                  .sort((a, b) => a.Name.localeCompare(b.Name))
                  ?.map((el, i) => {
                    if (el.Name) {
                      return (
                        <option
                          key={i}
                          className="form-control"
                          value={el.Name}
                        >
                          {el.Name}
                        </option>
                      );
                    }
                  })}
              </select>
            </>
          )}
          {showHeading && (
            <div className="heading mx-auto">
              <p>Action</p>
              <p>Date</p>
              <p>Site</p>
              <p>Province</p>
              <p>Performer(s)</p>
              <p>Notes</p>
            </div>
          )}

          {(specificEvent && (
            <ViewEvent
              event={specificEvent}
              func={viewAllEvents}
              sites={sites}
              artists={artists}
            />
          )) ||
            (eventData &&
              eventData.length &&
              userType === "Admins" &&
              eventData?.map((el, i) => {
                let performerNames = artists
                  .sort((a, b) => a.Name.localeCompare(b.Name))
                  ?.map((artist) => {
                    if (el.performers.includes(artist._id)) {
                      return artist.Name;
                    }
                  })
                  .filter((el) => el !== undefined);
                let currentSite = sites.find(
                  (site) => site._id === el.location
                );

                if (Province && currentSite?.Province === Province) {
                  return (
                    <Event
                      key={i}
                      _id={el._id}
                      name={el.name}
                      time={el.time}
                      date={el.date}
                      location={currentSite}
                      notes={el.notes}
                      performers={performerNames}
                      sites={sites}
                      func={selectEvent}
                      hideHeading={hideHeading}
                    ></Event>
                  );
                }
                if (performerNames && performerNames.includes(performers)) {
                  return (
                    <Event
                      key={i}
                      _id={el._id}
                      name={el.name}
                      time={el.time}
                      date={el.date}
                      location={currentSite}
                      notes={el.notes}
                      performers={performerNames}
                      sites={sites}
                      func={selectEvent}
                      hideHeading={hideHeading}
                    ></Event>
                  );
                }
                if (selectedSite && currentSite.Name === selectedSite) {
                  return (
                    <Event
                      key={i}
                      _id={el._id}
                      name={el.name}
                      time={el.time}
                      date={el.date}
                      location={currentSite}
                      notes={el.notes}
                      performers={performerNames}
                      sites={sites}
                      func={selectEvent}
                      hideHeading={hideHeading}
                    ></Event>
                  );
                }
                if (!Province && !performers && !selectedSite) {
                  return (
                    <Event
                      key={i}
                      _id={el._id}
                      name={el.name}
                      time={el.time}
                      date={el.date}
                      location={currentSite}
                      notes={el.notes}
                      performers={performerNames}
                      sites={sites}
                      func={selectEvent}
                      hideHeading={hideHeading}
                    ></Event>
                  );
                }

                return;
              })) ||
            (eventData &&
              eventData.length &&
              userType === "Artists" &&
              eventData?.map((el, i) => {
                let performerNames = artists
                  .sort((a, b) => a.Name.localeCompare(b.Name))
                  ?.map((artist) => {
                    if (el.performers.includes(artist._id)) {
                      return artist.Name;
                    }
                  })
                  .filter((el) => el !== undefined);

                if (performerNames.includes(artistFullName)) {
                  if (!haveEvents) {
                    setHaveEvents(true);
                  }
                  let currentSite = sites.find(
                    (site) => site._id === el.location
                  );

                  return (
                    <Event
                      key={i}
                      _id={el._id}
                      name={el.name}
                      time={el.time}
                      date={el.date}
                      location={currentSite}
                      notes={el.notes}
                      performers={performerNames}
                      sites={sites}
                      func={selectEvent}
                      hideHeading={hideHeading}
                    ></Event>
                  );
                }
                return;
              })) ||
            (eventData &&
              eventData.length &&
              userType === "Sites" &&
              eventData?.map((el, i) => {
                let performerNames = artists
                  ?.map((artist) => {
                    if (el.performers.includes(artist._id)) {
                      return artist.Name;
                    }
                  })
                  .filter((el) => el !== undefined);
                let currentSite = sites.find(
                  (site) => site._id === el.location
                );
                if (currentSite?.Name === eventSiteName) {
                  if (!haveEvents) {
                    setHaveEvents(true);
                  }
                  return (
                    <Event
                      key={i}
                      _id={el._id}
                      name={el.name}
                      time={el.time}
                      date={el.date}
                      location={currentSite}
                      notes={el.notes}
                      performers={performerNames}
                      sites={sites}
                      func={selectEvent}
                      hideHeading={hideHeading}
                    ></Event>
                  );
                }
              }))}
          {eventData &&
            eventData.length &&
            userType !== "Admins" &&
            !haveEvents && (
              <h5 className="text-center py-5">
                You do not have any upcoming concerts
              </h5>
            )}
          <ScrollToTop smooth />
        </div>
      </UserTypeContext.Provider>
    </UserLoginContext.Provider>
  );
};

export default EventList;
