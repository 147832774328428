import React, { useState, useEffect } from "react";

import ViewSite from './view-site';
import ScrollToTop from "react-scroll-to-top";
import {
    faEye
  } from '@fortawesome/free-solid-svg-icons'
  
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  
  
const SiteList = ({ sites, showheadings, province, siteName }) => {
    const [_id, set_ID] = useState();
    const [Name, setName] = useState();
    const [userName, setUserName] = useState();
    const [Contact, setContact] = useState();
    const [Phone, setPhone] = useState();
    const [Email, setEmail] = useState();
    const [StreetAddress, setStreetAddress] = useState();
    const [City, setCity] = useState();
    const [Province, setProvince] = useState();
    const [PostalCode, setPostalCode] = useState();
    const [Directions, setDirections] = useState();
    const [SpecialNotes, setSpecialNotes] = useState();
    const [specificSite, setSpecificSite] = useState(false);
    const back = () => { setSpecificSite(false); showheadings(true) }

    useEffect(() => {
        if (!specificSite) {
            showheadings(true);
            if (localStorage.getItem('scroll')) {
                window.scrollTo(0, parseInt(localStorage.getItem('scroll')));
            }
        }
    },[])
    useEffect(() => {
        if (!specificSite) {
            if (localStorage.getItem('scroll')) {
                window.scrollTo(0, parseInt(localStorage.getItem('scroll')));
            }
        }
    }, [specificSite])

    return (
        <>
            {
                (!specificSite &&
                    sites?.map((site, i) => {
                        if (siteName && siteName === site.Name) {
                            return (
                                <div key={i}>
                                    {
                                        <div className="artistList mb-3 mb-lg-0 mx-auto">
                                            <div className="d-flex flex-column">
                                                <button className="button-custom" onClick={() => {
                                                    set_ID(site._id);
                                                    setName(site.Name);
                                                    setUserName(site.Username);
                                                    setPhone(site.Phone);
                                                    setCity(site.City);
                                                    setProvince(site.Province);
                                                    setEmail(site.Email);
                                                    setDirections(site.Directions);
                                                    setSpecialNotes(site.SpecialNotes);
                                                    setPostalCode(site.PostalCode);
                                                    setContact(site.Contact);
                                                    setStreetAddress(site.StreetAddress);
                                                    setSpecificSite(true);
                                                    showheadings(false);
                                                    localStorage.setItem('scroll', window.scrollY);
                                                }}>View
                                                    <FontAwesomeIcon className="px-2" icon={faEye} />
                                                </button>
                                            </div>
                                            <p  className={`${site.Name && "d-flex flex-wrap border-lg-none border-bottom"}`}>{site.Name}</p>
                                            <p className={`${site.Username && "d-flex flex-wrap border-lg-none border-bottom"}`}>{site.Username}</p>
                                            <p className={`${site.StreetAddress && "d-flex flex-wrap border-lg-none border-bottom"}`}>{site.StreetAddress}</p>
                                            <p className={`${site.City && "d-flex flex-wrap border-lg-none border-bottom"}`}>{site.City}</p>
                                            <p className={`${site.Province && "d-flex flex-wrap border-lg-none border-bottom"}`}>{site.Province}</p>
                                        </div>
                                    }
                                </div>
                            )
                        }
                        else if (province && site.Province === province && site.Name) {
                            return (
                                <div key={i}>
                                    {
                                        <div className="artistList mb-3 mb-lg-0 mx-auto">
                                            <div className="d-flex flex-column">
                                                <button className="button-custom" onClick={() => {
                                                    set_ID(site._id);
                                                    setName(site.Name);
                                                    setUserName(site.Username);
                                                    setPhone(site.Phone);
                                                    setCity(site.City);
                                                    setProvince(site.Province);
                                                    setEmail(site.Email);
                                                    setDirections(site.Directions);
                                                    setSpecialNotes(site.SpecialNotes);
                                                    setPostalCode(site.PostalCode);
                                                    setContact(site.Contact);
                                                    setStreetAddress(site.StreetAddress);
                                                    setSpecificSite(true);
                                                    showheadings(false);
                                                    localStorage.setItem('scroll', window.scrollY);
                                                }}>View
                                                    <FontAwesomeIcon className="px-2" icon={faEye} />
                                                </button>
                                            </div>
                                            <p  className={`${site.Name && "d-flex flex-wrap border-lg-none border-bottom"}`}>{site.Name}</p>
                                            <p className={`${site.Username && "d-flex flex-wrap border-lg-none border-bottom"}`}>{site.Username}</p>
                                            <p className={`${site.StreetAddress && "d-flex flex-wrap border-lg-none border-bottom"}`}>{site.StreetAddress}</p>
                                            <p className={`${site.City && "d-flex flex-wrap border-lg-none border-bottom"}`}>{site.City}</p>
                                            <p className={`${site.Province && "d-flex flex-wrap border-lg-none border-bottom"}`}>{site.Province}</p>
                                        </div>
                                    }
                                </div>
                            )
                        }
                        else if (!province && !siteName && site.Name) {
                            return (
                                <div key={i}>
                                    {

                                        <div className="artistList mb-3 mb-lg-0 mx-auto">
                                            <div className="d-flex flex-column">
                                                <button className="button-custom" onClick={() => {
                                                    set_ID(site._id);
                                                    setName(site.Name);
                                                    setUserName(site.Username);
                                                    setPhone(site.Phone);
                                                    setCity(site.City);
                                                    setProvince(site.Province);
                                                    setEmail(site.Email);
                                                    setDirections(site.Directions);
                                                    setSpecialNotes(site.SpecialNotes);
                                                    setPostalCode(site.PostalCode);
                                                    setContact(site.Contact);
                                                    setStreetAddress(site.StreetAddress);
                                                    setSpecificSite(true);
                                                    showheadings(false);
                                                    localStorage.setItem('scroll', window.scrollY);

                                                }}>View
                                                    <FontAwesomeIcon className="px-2" icon={faEye} />
                                                </button>
                                            </div>
                                            <p  className={`${site.Name && "d-flex flex-wrap border-lg-none border-bottom"}`}>{site.Name}</p>
                                            <p className={`${site.Username && "d-flex flex-wrap border-lg-none border-bottom"}`}>{site.Username}</p>
                                            <p className={`${site.StreetAddress && "d-flex flex-wrap border-lg-none border-bottom"}`}>{site.StreetAddress}</p>
                                            <p className={`${site.City && "d-flex flex-wrap border-lg-none border-bottom"}`}>{site.City}</p>
                                            <p className={`${site.Province && "d-flex flex-wrap border-lg-none border-bottom"}`}>{site.Province}</p>
                                        </div>
                                    }
                                </div>
                            )
                        }
                    }))
            }
            {
                (specificSite && <ViewSite name={Name} back={back} userName={userName} email={Email} specialNotes={SpecialNotes} contact={Contact} phone={Phone} streetAddress={StreetAddress} directions={Directions} city={City} province={Province} postalCode={PostalCode} _id={_id} />)
            }
            <ScrollToTop smooth />

        </>
    )
}

export default SiteList;