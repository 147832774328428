import React from "react";

const Footer = () => {
    return (
        <div style={{
            backgroundColor:'rgb(63, 169, 223)'
        }} className="d-flex py-2 text-light justify-content-center w-100">
            &#169; {new Date().getFullYear()} CONCERT IN CARE  ALL RIGHTS RESERVED. 
        </div>
    )

}

export default Footer;