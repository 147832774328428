import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { tConvert } from "../helper/tConvert";
import { UserTypeContext, LoginContext } from "../context/event-context";
import axios from "axios";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Event = ({
  _id,
  name,
  time,
  date,
  location,
  notes,
  performers,
  sites,
  func,
  hideHeading,
}) => {
  const [userType, setUserType] = useContext(UserTypeContext);
  const [login, setLogin] = useContext(LoginContext);
  const [poster, setPoster] = useState("");
  const [artists, setArtists] = useState([]);
  // const [formatedDate, setFormatedDate] = useState(date && date.split("T")[0].split("-").reverse().join("-").split("-"));
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let intlDate = new Intl.DateTimeFormat("en-US", { dateStyle: "full" }).format(
    new Date(
      months[parseInt(date.split("T")[0].split("-")[1] - 1)] +
        " " +
        date.split("T")[0].split("-")[2] +
        " " +
        date.split("T")[0].split("-")[0]
    )
  );
  let formatedDate = intlDate.split(",");
  let formatedYear = formatedDate[2];
  let formatedDayMonth = formatedDate[0] + formatedDate[1];

  return (
    <UserTypeContext.Provider value={[userType, setUserType]}>
      <LoginContext.Provider value={[login, setLogin]}>
        <div className="eventDetails  mb-3 mb-lg-0 mx-auto">
          <div className="d-flex flex-column justify-content-center">
            <button
              className="button-custom"
              onClick={() => {
                localStorage.setItem("scroll", parseInt(window.scrollY));
                func({ _id, name, date, time, location, notes, performers });
                hideHeading();
              }}
            >
              View
              <FontAwesomeIcon className="px-2" icon={faEye} />
            </button>
          </div>
          <p className="border-lg-none border-bottom">
            {formatedDayMonth + ", " + formatedYear} &nbsp; - &nbsp;
            {tConvert(time)}{" "}
          </p>
          <p className={`${location.Name && "border-lg-none border-bottom"}`}>
            {location.Name}
          </p>
          <p
            className={`${location.Province && "border-lg-none border-bottom"}`}
          >
            {location.Province}
          </p>
          <div className={`${performers && "border-lg-none border-bottom"}`}>
            {performers?.map((el, i) => {
              return <p key={i}>{el}</p>;
            })}
          </div>
          <p className={`${notes && "border-lg-none border-bottom"}`}>
            {notes}
          </p>
        </div>
      </LoginContext.Provider>
    </UserTypeContext.Provider>
  );
};

export default Event;
