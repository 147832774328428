import React, { useContext, useState } from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import "../styles/style.css";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import FileBase64 from 'react-file-base64';
import { RefreshContext, DisplayContext } from "../context/event-context"
import { DatePicker, TimePicker, Textarea } from "react-rainbow-components";
import Swal from 'sweetalert2'
import { headerConfig } from "../helper/headerConfig";

const AddEvent = ({ sites, artists }) => {
    const [eventArtist, setEventArtist] = useState([]);
    const [eventLocation, setEventLocation] = useState("Event Location");
    const [eventNotes, setEventNotes] = useState("");
    const [eventDate, setEventDate] = useState(null);
    const [eventTime, setEventTime] = useState(null);
    const [refreshKey, setRefreshKey] = useContext(RefreshContext);
    const [display, setDisplay] = useContext(DisplayContext);
    const [disabled, setDisabled] = useState(false);
    const [file, setFile] = useState("");
    const [posterName, setPosterName] = useState("");

    const containerStyles = {
        maxWidth: 400,
    };

    const options = artists && artists.sort((a, b) => a.Name.localeCompare(b.Name))?.map((el) => {
        if (el.Name) {
            return { label: el.Name, value: el.Name };
        }
    }).filter((el) => el !== undefined);

    const artistNames = eventArtist && eventArtist.map((el) => {
        if (el && el.label) {
            return el.label
        }
    });

    const addEvent = (e) => {
        e.preventDefault();
        setDisabled(true);
        const config = headerConfig();
        if (artistNames && eventLocation && eventDate && eventTime) {
            axios.post(`${process.env.GATSBY_API_URL}/insertEvent`,
                { artistNames, eventLocation, eventNotes, eventDate, eventTime, file, posterName },
                config)
                .then((el) => {
                    let siteName = sites.find((site) => site._id === el.data.data.location)?.Name;
                    let artistNames = artists.sort((a, b) => a.Name.localeCompare(b.Name))?.map((artist) => {
                        if (el.data.data.performers.includes(artist._id)) {
                            return artist.Name;
                        }
                    }
                    ).filter((el) => el !== undefined);
                    let strArtistNames = artistNames?.join(', ');
                    Swal.fire({
                        title: 'Content Saved!',
                        text: 'Event Added Successfully!',
                        icon: 'success',
                        html:
                            `<div class="d-flex flex-column justify-content-start align-items-start">    
                            <p class="text-dark">Event Performers : ${strArtistNames}</p>
                            <p class="text-dark">Event Location : ${siteName}</p>
                            <p class="text-dark">Event Date : ${el.data.data.date}</p>
                            <p class="text-dark">Event Time : ${el.data.data.time}</p>
                            <p class="text-dark">Event Notes : ${el.data.data.notes}</p>
                            </div>
                            `
                    })
                    setDisabled(false);
                    setDisplay([true, false, false, false, false, false, false]);
                    setRefreshKey(refreshKey + 1);
                })
                .catch(err => console.log(err));
        } else {
            setDisabled(false);
            Swal.fire("Missing Fields");
        }
    };

    return (
        (artists && (artists.length)) ?
            <>
                <h2 className="d-flex justify-content-center">Add New Event</h2>
                <form onSubmit={addEvent}>

                    {sites && sites.length &&
                        <>
                            <div className="form-group row py-2">
                                <label className="col-lg-3 col-form-label" htmlFor="location">Location</label>
                                <div className="col-sm-9">
                                    <select required className="form-control" name="location" id="location" onChange={
                                        (event) => {
                                            let currentSite = sites.find((site) => site.Name === event.target.value)
                                            setEventLocation(currentSite._id);
                                            // setSelectedSite(currentSite._id);
                                        }
                                    }
                                    >
                                        <option value="">---Event Site---</option>
                                        {sites.sort((a, b) => a.Name.localeCompare(b.Name))?.map((el, i) => {
                                            if (el.Name !== "") {
                                                return <option key={i} className="form-control" value={el.Name}>{el.Name}</option>
                                            }
                                        })
                                        }
                                    </select>
                                </div>
                            </div>
                        </>
                    }

                    <div className="form-group row py-2">
                        <label className="col-lg-3 col-form-label" htmlFor="artists">
                            Select Artists
                        </label>
                        <div className="col-sm-9">
                            <MultiSelect
                                required={true}
                                name="artits"
                                options={options}
                                value={eventArtist}
                                onChange={setEventArtist}
                                labelledBy="Select"
                            />
                        </div>
                    </div>

                    <div className="form-group row py-2">
                        <label className="col-lg-3 col-form-label" htmlFor="notes">Notes</label>
                        <div className="col-sm-9">
                            <Textarea
                                id="example-textarea-1"
                                rows={4}
                                placeholder="Notes"
                                onChange={
                                    (event) => {
                                        setEventNotes(event.target.value);
                                    }}
                                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                            />
                        </div>
                    </div>


                    <div className="form-group row py-2">
                        <label className="col-lg-3 col-form-label" htmlFor="eventDate">Date</label>
                        <div className="col-sm-9">
                            <DatePicker
                                id="datePicker-1"
                                value={eventDate}
                                onChange={(eventDate) => { setEventDate(eventDate) }}
                                formatStyle="large"
                            />
                        </div>
                    </div>

                    <div className="form-group row py-2">
                        <label className="col-lg-3 col-form-label" htmlFor="eventTime">Time</label>
                        <div className="col-sm-9">
                            <TimePicker
                                id="time-picker-1"
                                value={eventTime}
                                onChange={value => setEventTime(value)}
                                style={containerStyles}
                                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                            />
                        </div>
                    </div>

                    <div className="form-group row py-2">
                        <label className="col-lg-3 col-form-label" htmlFor="poster">
                            Poster
                        </label>
                        <div className="col-sm-9">
                            <FileBase64
                                name="poster"
                                type="file"
                                multiple={false}
                                onDone={
                                    ({ name, base64 }) => { setPosterName(name); setFile(base64) }}
                            />
                        </div>
                    </div>
                    <button disabled={disabled} type="submit" className="button-custom" >Submit</button>
                </form>
            </> : <div className="d-flex justify-content-center align-items-center loader-container">
                <p>Loading ...</p>
            </div>
    )
}

export default AddEvent;
