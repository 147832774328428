import React, { useContext, useState, useEffect } from 'react';
import { UserLoginContext, UserTypeContext, LoginContext, RefreshContext, DisplayContext } from "../context/event-context";
import axios from "axios";
import Swal from 'sweetalert2'
import { MultiSelect } from "react-multi-select-component";
import FileBase64 from 'react-file-base64';
import { tConvert } from '../helper/tConvert';
import { DatePicker, TimePicker } from "react-rainbow-components";
import { headerConfig } from "../helper/headerConfig";
import { Form } from "react-bootstrap";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  faDownload,
  faTrashCan,
  faPenToSquare,
  faBackward,
  faEye
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ViewEvent({ event, sites, artists, func }) {
  const [display, setDisplay] = useContext(DisplayContext);
  const [userState, setUserState] = useContext(UserLoginContext);
  const [userType, setUserType] = useContext(UserTypeContext);
  const [refreshKey, setRefreshKey] = useContext(RefreshContext);
  const [date, setDate] = useState(event.date);
  const [location, setLocation] = useState(event.location);
  const [notes, setNotes] = useState(event.notes);
  const [performers, setPerformers] = useState(event.performers);
  const [currentSelectedSite, setCurrentSelectedSite] = useState("");
  const [poster, setPoster] = useState("");
  const [posterName, setPosterName] = useState("");
  const [login, setLogin] = useContext(LoginContext);
  const [time, setTime] = useState(event.time);
  const [formatedTime, setFormatedTime] = useState(event.time);
  const [formmatedDate, setFormatedDate] = useState(event.date);
  const [currentPerformersData, setCurrentPerformersData] = useState([]);
  const [_id, set_id] = useState(event._id);
  const [viewEvent, setViewEvent] = useState(true);

  const [updatePoster, setUpdatePoster] = useState(false);

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  let eventPerformers = [];
  const [selectedPerformers, setSelectedPerformers] = useState(performers && performers.length && performers?.map((performer) => {
    return {
      label: performer,
      value: performer
    }
  }));
  const deleteEvent = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const config = headerConfig();
        axios.post(`${process.env.GATSBY_API_URL}/delete`, { _id }, config)
          .then(() => {
            Swal.fire(
              'Deleted!',
              'Event Deleted!',
              'success'
            );
          }).then(() => {
            setDisplay([true, false, false, false, false, false, false]);
            setRefreshKey(refreshKey + 1);
            func();
          })
      }
    })
  }
  async function getEventPoster() {
    axios.post(`${process.env.GATSBY_API_URL}/getEventPoster`, { _id: event._id })
      .then((data) => {
        setPoster(data.data.data);
      })
  }

  useEffect(() => {
    let tempSite = sites.find(site => site.Name === event.location);
    tempSite && setCurrentSelectedSite(tempSite.Name) && setLocation(tempSite._id);
    getEventPoster();
  }, []);

  useEffect(() => {
    performers && performers.length && performers?.map((performer) => {
      let artistData = artists && artists.find((artist) => artist.Name === performer)
      async function foo(artistData) {
        await axios.post(`${process.env.GATSBY_API_URL}/getProfileImage`, { _id: artistData._id })
          .then((data) => {
            if (data.data.data) {
              artistData["file"] = data.data.data;
            }
            setCurrentPerformersData(currentPerformersData => [...currentPerformersData, artistData]);
          })
      }
      foo(artistData);
    });
  }, [])


  let intlDate = date && typeof date === 'string' && new Intl.DateTimeFormat('en-US', { dateStyle: 'full' }).format(new Date(months[parseInt(date.split("T")[0].split("-")[1] - 1)] + " " + date.split("T")[0].split("-")[2] + " " + date.split("T")[0].split("-")[0]))
  let formatedDate = intlDate && typeof intlDate === 'string' && intlDate.split(",");
  let formatedYear = formatedDate[2]
  let formatedDayMonth = formatedDate[0] + formatedDate[1];

  const options = artists && artists.sort((a, b) => a.Name.localeCompare(b.Name))?.map((el) => {
    if (el.Name) {
      return { label: el.Name, value: el.Name };
    }
  }).filter((el) => el !== undefined);

  const artistNames = selectedPerformers && selectedPerformers.length && selectedPerformers.map((el) => {
    if (el.label)
      return el.label
  });

  const updateEvent = async (e) => {
    e.preventDefault();
    const config = headerConfig();
    if (date, formatedTime, location, notes, artistNames) {
      let eventUpdatedData = { _id: event._id, date: date, time: formatedTime, location: location._id, notes: notes, performers: artistNames }
      if (updatePoster) {
        eventUpdatedData['posterName'] = posterName;
        eventUpdatedData['poster'] = poster;
      }
      await axios.post(`${process.env.GATSBY_API_URL}/updateEvent`, eventUpdatedData, config)
        .then((el) => {
          Swal.fire({
            title: 'Content Saved!',
            text: 'Event Added Successfully!',
            icon: 'success',
          })
          setDisplay([true, false, false, false, false, false, false]);
          setRefreshKey(refreshKey + 1);
          func();
        }).catch(err => alert(err));
    } else {
      Swal.fire("Missing Fields");
    }
  };


  const deletePoster = async (e) => {
    e.preventDefault();
    let eventUpdatedData = { _id: event._id };
    const config = headerConfig();

    await axios.post(`${process.env.GATSBY_API_URL}/deletePoster`, eventUpdatedData, config)
      .then((el) => {
        if (el) {
          Swal.fire({
            title: "Poster Removed",
            icon: 'success',
          })
          setDisplay([true, false, false, false, false, false, false]);
          setRefreshKey(refreshKey + 1);
          func();
        }
      })
  }


  return (
    <UserTypeContext.Provider value={[userType, setUserType]} >
      <UserLoginContext.Provider value={[userState, setUserState]} >
        <LoginContext.Provider value={[login, setLogin]}>
          {
            (event && login && userType === 'Admins' && options && sites && sites.length) &&
            <section className='d-flex flex-column flex-md-row gap-3 gap-lg-0 flex-lg-row px-lg-5 justify-content-between align-items-start'>
              <button className="button-custom" onClick={() => { func() }}>
                <FontAwesomeIcon className='px-2' icon={faBackward} />
                Back
              </button>
              {
                login && userType === 'Admins' && (!viewEvent) &&
                <>
                  <Form className='ms-0 ms-lg-auto'>
                    <Form.Check
                      type="switch"
                      id="custom-update-switch"
                      label="Update Poster"
                      onChange={() => setUpdatePoster(!updatePoster)}
                    />
                  </Form>
                  {
                    poster && <form onSubmit={deletePoster}>
                      <button className="button-custom bg-danger" type='submit'>
                        Delete Poster <FontAwesomeIcon className='px-2' icon={faTrashCan} />
                      </button>
                    </form>
                  }
                </>

              }
              <div className='d-flex'>
                {
                  login && userType === 'Admins' && (!viewEvent) ?
                    <button className="button-custom" onClick={() => setViewEvent(true)} >View
                      <FontAwesomeIcon className="px-2" icon={faEye} />
                    </button>
                    : <button className="button-custom" onClick={() => setViewEvent(false)} >Edit
                      <FontAwesomeIcon className='px-2' icon={faPenToSquare} />
                    </button>
                }
                {
                  login && userType === 'Admins' &&
                  <button className="button-custom bg-danger" onClick={deleteEvent} >
                    Delete
                    <FontAwesomeIcon className='px-2' icon={faTrashCan} />
                  </button>
                }
              </div>
            </section>
          }
          {
            (event && login && userType === 'Admins' && options && sites && sites.length && !viewEvent) &&
            <form onSubmit={updateEvent}>
              <div className="form-group row py-2">
                <label className="col-lg-3 col-form-label" htmlFor="eventDate">Date</label>
                <div className="col-sm-9">
                  <DatePicker
                    value={date}
                    onChange={(date) => { setDate(date) }}
                    formatStyle="large"
                  />
                </div>
              </div>
              <div className="form-group row py-2">
                <label className="col-lg-3 col-form-label" htmlFor="eventTime">Time</label>
                <div className="col-sm-9">
                  <TimePicker
                    id="time-picker-1"
                    value={formatedTime}
                    onChange={(formatedTime) => { setFormatedTime(formatedTime) }}
                    className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
                  />
                </div>
              </div>

              <div className="form-group row py-2">
                <label className="col-lg-3 col-form-label" htmlFor="location">Location</label>
                <div className="col-sm-9">
                  <select className="form-control" value={location && location.Name} name="location" id="location" onChange={(event) => {
                    let currentSite = sites.find((site) => site.Name === event.target.value)
                    setLocation(currentSite);
                  }}>
                    <option value="">---Event Site---</option>
                    {
                      sites && sites.length && sites?.map((el, i) => {
                        if (el.Name) {
                          return <option key={i} className="form-control" value={el.Name}>{el.Name}</option>
                        }
                      })
                    }
                  </select>
                </div>
              </div>

              <div className="form-group row py-2">
                <label className="col-lg-3 col-form-label" htmlFor="artists">Artists</label>
                <div className="col-sm-9">
                  <MultiSelect
                    name="artists"
                    options={options}
                    value={selectedPerformers || []}
                    onChange={setSelectedPerformers}
                    labelledBy="Select"
                  />
                </div>
              </div>

              <div className="form-group row py-2">
                <label className="col-lg-3 col-form-label" htmlFor="notes">Notes</label>
                <div className="col-sm-9">
                  <input className="form-control" name="notes" onChange={(event) => setNotes(event.target.value)} value={notes}></input>
                </div>
              </div>
              {
                updatePoster &&
                <div className="form-group row py-2">
                  <label className="col-lg-3 col-form-label" htmlFor="poster">
                    Poster
                  </label>
                  <div className="col-sm-9">
                    <FileBase64
                      required
                      name="poster"
                      type="file"
                      multiple={false}
                      onDone={
                        ({ name, base64 }) => { setPosterName(name); setPoster(base64) }}
                    />
                  </div>
                </div>
              }

              <button className='button-custom' type='submit'>Update Event</button>
            </form>

          }

          {
            (event && login && userType !== 'Admins' || viewEvent) &&
            <div style={{
              transition: "all .3s ease-in-out"
            }}
              className='mx-auto py-lg-5 px-lg-5 px-3'>
              {
                login && userType !== 'Admins' &&
                <button className="button-custom" onClick={() => {
                  func()
                }}>
                  <FontAwesomeIcon className='px-2' icon={faBackward} />
                  Back
                </button>
              }
              {typeof date === "string" &&
                <>
                  <h3><strong>Date:&nbsp;&nbsp;</strong>{formatedDayMonth + ", " + formatedYear + " - " + tConvert(time)}</h3>
                </>
              }
              <div className='d-flex flex-row flex-wrap'>
                <h3><strong>Location:&nbsp;&nbsp;  </strong></h3> <h3> {location.Name}</h3>
              </div>
              <div className='d-flex flex-wrap'>
                <h3><strong>Performer(s):&nbsp;&nbsp; </strong> </h3>
                {artists && artists.length && artists.sort((a, b) => a.Name.localeCompare(b.Name))?.map((el, i) => {
                  if (performers && performers.includes(el.Name)) {
                    eventPerformers.push(el.Name)
                  }
                })
                  && <h3>
                    {eventPerformers.join(", ")}
                  </h3>
                }
              </div>
              <hr></hr>
              <div className='d-flex flex-column'>
                {
                  currentPerformersData && currentPerformersData?.map((el, i) => {
                    return (
                      <div key={i}>
                        <div className='d-flex align-items-center justify-content-start py-2'>
                          <p className='d-lg-flex align-items-lg-start'>
                            {
                              el && el.file &&
                              <LazyLoadImage style={{ width: "100%", maxWidth: "300px", minWidth: "300px" }} className='float-lg-left py-3' src={el.file} />
                            }
                            <span
                              className='col-lg-8 p-3'>
                              <span className='pb-2 d-block'><strong>{el.Name}</strong></span>
                              <br></br>
                              <span>{el.Bio}</span>
                            </span>
                          </p>
                        </div>
                        <hr></hr>
                      </div>
                    )
                  })
                }
              </div>

              <div>
                <div >
                  <p><strong>Street Address:&nbsp;&nbsp;</strong>{
                    location.StreetAddress}, {location.City}</p>
                  <p className='py-2'>
                    <strong>Province:&nbsp;&nbsp;</strong>
                    {location.Province}
                  </p>
                  <p className="py-2"><strong>Directions:&nbsp;&nbsp;</strong>{location.Directions}</p>
                  <p className="py-2"><strong>Special Notes:&nbsp;&nbsp;</strong>{location.SpecialNotes}</p>
                </div>
                <p className='py-2'><strong>Note:&nbsp;&nbsp;</strong> {notes}</p>
                <hr></hr>
                {
                  poster &&
                  <div className='d-flex flex-lg-row flex-column gap-3 gap-lg-0 bg-dark p-3 text-light justify-content-between'>
                    <div className='d-flex gap-2'>
                      {
                        { poster } &&
                        <button style={{

                          margin: "0",
                          textAlign: "center"
                        }} className='button-custom' onClick={() => {
                          let pdfWindow = window.open("")
                          pdfWindow.document.write(
                            `<iframe width='100%' height='100%' src='${poster}'></iframe>`
                          )
                        }}>
                          Open Poster in New Tab
                        </button>
                      }
                      {
                        { poster }
                        &&
                        <a className="button-custom"
                          style={{

                            margin: "0",
                            textDecoration: "none",
                            textAlign: "center"
                          }} href={poster} download={formatedDayMonth + "_" + location.Name}>
                          <FontAwesomeIcon icon={faDownload} />
                        </a>
                      }
                    </div>
                    <div className=' d-flex justify-content-lg-center align-items-lg-center'>
                      {
                        ((encodeURI(poster).split(/%..|./).length - 1) / 1000) + " KB"
                      }
                    </div>

                  </div>
                }
              </div>
              <button className="button-custom ms-0 my-5" onClick={() => {
                func()
              }}>
                <FontAwesomeIcon className='px-2' icon={faBackward} />
                Back
              </button>
            </div>
          }
        </LoginContext.Provider>
      </UserLoginContext.Provider>
    </UserTypeContext.Provider>
  )
}

