import React, { useState, useContext } from "react";
import axios from "axios";
import Swal from 'sweetalert2'
import { Form } from "react-bootstrap";
import { RefreshContext, DisplayContext } from "../context/event-context"
import { headerConfig } from "../helper/headerConfig";
import {
    faTrashCan,
    faPenToSquare,
    faBackward,
    faEye
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const ViewSite = ({ _id, name, back, userName, contact, phone, email, streetAddress, city, province, postalCode, directions, specialNotes }) => {
    const [refreshKey, setRefreshKey] = useContext(RefreshContext);
    const [Name, setName] = useState(name);
    const [Username, setUserName] = useState(userName);
    const [Contact, setContact] = useState(contact);
    const [Phone, setPhone] = useState(phone);
    const [Email, setEmail] = useState(email);
    const [StreetAddress, setStreetAddress] = useState(streetAddress);
    const [City, setCity] = useState(city);
    const [Province, setProvince] = useState(province);
    const [PostalCode, setPostalCode] = useState(postalCode);
    const [Directions, setDirections] = useState(directions);
    const [SpecialNotes, setSpecialNotes] = useState(specialNotes);
    const [Password, setPassword] = useState();
    const [updatePassword, setUpdatePassword] = useState(false);
    const [viewSite, setViewSite] = useState(true);
    const [display, setDisplay] = useContext(DisplayContext);
    const provinces = [
        "NL",
        "PE",
        "NS",
        "NB",
        "QC",
        "ON",
        "MB",
        "SK",
        "AB",
        "BC",
        "YT",
        "NT",
        "NU"
    ];

    const updateSite = async (e) => {
        e.preventDefault();
        let updatedData = { _id, Name, Username, Contact, Phone, Email, StreetAddress, City, Province, PostalCode, Directions, SpecialNotes };
        if (updatePassword) {
            updatedData["Password"] = Password;
        }
        const config = headerConfig();
        await axios.post(`${process.env.GATSBY_API_URL}/updateSite`, updatedData, config)
            .then((el) => {
                let response = el.data.data;
                Swal.fire({
                    title: 'Content Saved!',
                    text: 'Artist Updated Successfully!',
                    icon: 'success',
                    html: `<div class="d-flex flex-column justify-content-start align-items-start">    
                    <p class="text-dark">Username : ${response.Username}</p>
                    <p class="text-dark">Name : ${response.Name}</p>
                    <p class="text-dark">Email : ${response.Email}</p>
                    <p class="text-dark">Contact : ${response.Contact}</p>
                    <p class="text-dark">CellPhone : ${response.Phone}</p>
                    <p class="text-dark">Street Address : ${response.StreetAddress}</p>
                    <p class="text-dark">City : ${response.City}</p>
                    <p class="text-dark">Province : ${response.Province}</p>
                    <p class="text-dark">Special Notes : ${response.SpecialNotes}</p>
                    <p class="text-dark">Directions : ${response.Directions}</p>
                </div>
                `
                })
                setDisplay([false, false, true, false, false, false, false]);
                setRefreshKey(refreshKey + 1);
            }).catch((err) => console.log(err));
    }
    const deleteSite = (e) => {
        e.preventDefault();
        Swal.fire({
            title: 'Delete this site?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const config = headerConfig();
                axios.post(`${process.env.GATSBY_API_URL}/deleteSite`, { _id }, config)
                    .then(() => {
                        Swal.fire(
                            'Content Saved!',
                            'Site Deleted',
                            'success'
                        );
                        setDisplay([false, false, true, false, false, false, false]);
                        setRefreshKey(refreshKey + 1)
                    })
                    .catch(err => console.log(err));
            }
        });
    }

    return (
        <>
            {
                !viewSite &&
                <div>
                    <section className='d-flex justify-content-between flex-wrap'>
                        <button className="button-custom" onClick={() => back()}>
                            <FontAwesomeIcon className='px-2' icon={faBackward} />
                            Back
                        </button>
                        <Form>
                            <Form.Check
                                type="switch"
                                id="update-site-password-switch"
                                label="update password"
                                onChange={() => setUpdatePassword(!updatePassword)}
                            />
                        </Form>
                        <div className="d-flex">
                            {
                                (!viewSite) ?

                                    <button className="button-custom" onClick={() => setViewSite(true)} >View
                                        <FontAwesomeIcon className="px-2" icon={faEye} /></button>
                                    : <button className="button-custom" onClick={() => setViewSite(false)} >Edit
                                        <FontAwesomeIcon className='px-2' icon={faPenToSquare} /></button>
                            }

                            <button className="button-custom bg-danger" onClick={
                                deleteSite
                            } > Delete
                                <FontAwesomeIcon className='px-2' icon={faTrashCan} /></button>
                        </div>
                    </section>
                    <form onSubmit={updateSite}>

                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="siteName">Site Name</label>
                            <div className="col-sm-9">
                                <input name="siteName" className="form-control my-2" placeholder="site Name" onChange={(e) => setName(e.target.value)} value={Name} />
                            </div>
                        </div>
                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="siteUserName">Site User Name</label>
                            <div className="col-sm-9">
                                <input name="siteUserName" className="form-control my-2" placeholder="site Name" onChange={(e) => setUserName(e.target.value)} value={Username} />
                            </div>
                        </div>
                        {updatePassword
                            &&
                            <div className="form-group row py-2">
                                <label className="col-lg-3 col-form-label" htmlFor="site-password">Site Password</label>
                                <div className="col-sm-9">
                                    <input className="form-control" placeholder="password" name="site-password" onChange={
                                        (event) => setPassword(event.target.value)
                                    } type="password">
                                    </input>
                                </div>
                            </div>
                        }

                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="siteContact">Site Contact</label>
                            <div className="col-sm-9">
                                <input name="siteContact" className="form-control my-2" placeholder="Site contact" onChange={(e) => setContact(e.target.value)} value={Contact} />
                            </div>
                        </div>

                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="phone">Phone</label>
                            <div className="col-sm-9">
                                <input name="phone" className="form-control my-2" placeholder="Phone" type="text" onChange={(e) => setPhone(e.target.value)} value={Phone} />
                            </div>
                        </div>

                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="email">Email</label>
                            <div className="col-sm-9">
                                <input name="email" type="email" className="form-control my-2" placeholder="Email" onChange={(e) => setEmail(e.target.value)} value={Email} />
                            </div>
                        </div>

                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="streetaddress">Street Address</label>
                            <div className="col-sm-9">
                                <input name="streetaddress" className="form-control my-2" placeholder="Street Address" onChange={(e) => setStreetAddress(e.target.value)} value={StreetAddress} />
                            </div>
                        </div>

                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="streetaddress">City</label>
                            <div className="col-sm-9">
                                <input className="form-control my-2" placeholder="City" onChange={(e) => setCity(e.target.value)} value={City} />
                            </div>
                        </div>

                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="province">Province</label>
                            <div className="col-sm-9">
                                <select className="form-control" name="province" id="province" value={Province} onChange={(event) => setProvince(event.target.value)}>
                                    <option value="">---Province---</option>
                                    {
                                        provinces.sort()?.map((el, i) => {
                                            if (el) {
                                                return <option key={i} className="form-control" value={el}>{el}</option>
                                            }
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="postalcode">Postal Code</label>
                            <div className="col-sm-9">
                                <input name="postalcode" className="form-control my-2" placeholder="postalcode" onChange={(e) => setPostalCode(e.target.value)} value={PostalCode} />
                            </div>
                        </div>

                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="notes">Special Notes</label>
                            <div className="col-sm-9">
                                <textarea className="form-control my-2" placeholder="Special Notes" onChange={(e) => setSpecialNotes(e.target.value)} value={SpecialNotes} />
                            </div>
                        </div>

                        <div className="form-group row py-2">
                            <label className="col-lg-3 col-form-label" htmlFor="directions">Directions</label>
                            <div className="col-sm-9">
                                <textarea name="directions" className="form-control my-2" placeholder="Directions" onChange={(e) => setDirections(e.target.value)} value={Directions} />
                            </div>
                        </div>

                        <button className="button-custom" type="submit">Update Site</button>
                    </form>
                </div>
            }
            {
                viewSite && <>
                    <section className='d-flex justify-content-between flex-wrap'>
                        <button className="button-custom" onClick={() => back()}>
                            <FontAwesomeIcon className='px-2' icon={faBackward} />
                            Back
                        </button>
                        <div className="d-flex">
                            {
                                (!viewSite) ?

                                    <button className="button-custom" onClick={() => setViewSite(true)} >View
                                        <FontAwesomeIcon className="px-2" icon={faEye} />
                                    </button>
                                    : <button className="button-custom" onClick={() => setViewSite(false)} >Edit
                                        <FontAwesomeIcon className='px-2' icon={faPenToSquare} />
                                    </button>
                            }
                            <button className="button-custom bg-danger" onClick={
                                deleteSite
                            } >Delete
                                <FontAwesomeIcon className='px-2' icon={faTrashCan} />
                            </button>
                        </div>
                    </section>
                    <div className="d-flex flex-column container">
                        <p><strong>Name:&nbsp;&nbsp;  </strong> {Name}</p>
                        <p><strong>Username:&nbsp;&nbsp;</strong> {Username}</p>
                        <p><strong>Contact:&nbsp;&nbsp;</strong> {Contact}</p>
                        <p><strong>Phone:&nbsp;&nbsp;</strong> {Phone}</p>
                        <p><strong>Email: &nbsp;&nbsp;</strong>{Email}</p>
                        <p><strong>Street Address: &nbsp;&nbsp;</strong>{StreetAddress}</p>
                        <p><strong>City: &nbsp;&nbsp;</strong>{City}</p>
                        <p><strong>Province:&nbsp;&nbsp;</strong> {Province}</p>
                        <p><strong>Postal Code: &nbsp;&nbsp;</strong>{PostalCode}</p>
                        <p><strong>Directions:&nbsp;&nbsp;</strong> {Directions}</p>
                        <p><strong>Special Notes:&nbsp;&nbsp;</strong> {SpecialNotes}</p>
                    </div>
                </>
            }
        </>
    )
}

export default ViewSite;