import React, { useContext, useEffect } from 'react'
import logo from "../images/logo.png"
import darkLogo from "../images/logo-dark.png"
import { Link } from "gatsby";
import { LoginContext, DarkThemeContext } from "../context/event-context"
import axios from "axios";
import { Form } from "react-bootstrap";
const Header = () => {
    const [login, setLogin] = useContext(LoginContext);
    const [dark, setDark] = useContext(DarkThemeContext);

    const handleLogout = () => {
        axios.post(`${process.env.GATSBY_API_URL}/logout`)
            .then(() => {
                login && setLogin(false);
                localStorage.clear();
            }).then(() => {
                window.location.href = "https://concertsincare.ca/";
            })
            .catch(err => console.log(err));

    };
    const checkIfLoggedIn = async (token) => {
        try {
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            const response = await axios.post(`${process.env.GATSBY_API_URL}/verifyToken`, {}, config);
            return response;
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        function checkUserData() {
            const token = localStorage.getItem('token');
            if (token) {
                checkIfLoggedIn(token).then((res) => {
                    if (res) {
                        const tokenIsValid = res.data;
                        if (tokenIsValid.isUserValid) {
                            setLogin(true);
                        }
                    }
                })
            }
        }
        checkUserData();


    }, []);

    useEffect(() => {
        if (login) {
            setTimeout(() => {
                handleLogout()
            }, 60 * 60 * 1000);
        }
    }, [login])

    return (
        <div className=' header d-flex flex-row mx-auto justify-content-between'>
            <Link to='/'>
                <img style={{
                    objectFit: 'contain',
                    objectPosition: 'center',
                    maxHeight: "10rem",
                }} src={darkLogo} className="img-fluid logo-img" alt="Logo"></img>
            </Link>
            <div className='d-flex flex-column justify-content-evenly'>
                <div style={{
                    width: "80px"
                }} className="d-flex justify-content-center mx-auto px-1">
                    <Form>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            defaultChecked={!dark}
                            onChange={() => {
                                setDark(!dark);
                                dark && document.body.classList.add('dark');
                                dark && localStorage.setItem('theme', 'dark');
                                !dark
                                    && document.body.classList.contains('dark')
                                    && document.body.classList.remove('dark');
                                !dark && localStorage.setItem('theme', 'light');
                            }}
                        />
                    </Form>
                    <svg fill="#000000" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>moon-sun</title> <path d="M30.994 13.84l-6.241-2.361 1.076-6.586-6.299 2.203-3.409-5.736-3.409 5.736-6.299-2.203 1.076 6.586-6.241 2.361 5.057 4.354-3.263 5.821 6.672 0.084 1.242 6.556 5.166-4.224 5.166 4.224 1.242-6.556 6.672-0.084-3.263-5.821 5.057-4.354zM16.472 25.494c-4.977 0-9.012-4.035-9.012-9.012s4.035-9.012 9.012-9.012c2.328 0 4.45 0.883 6.049 2.332-0.55-0.214-1.132-0.364-1.736-0.425-0.239-0.024-0.486 0-0.731 0-3.929 0-7.099 3.17-7.099 7.099s3.17 7.099 7.099 7.099c0.9 0 1.76-0.179 2.551-0.492-1.609 1.495-3.764 2.41-6.133 2.41z"></path> </g></svg>
                </div>
                {
                    login
                    &&
                    <button className='btn ' onClick={handleLogout}>logout</button>
                }
            </div>

        </div>
    )
}
export default Header;