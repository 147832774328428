import axios from 'axios';
import React, { useState, useContext } from 'react';
import { UserLoginContext, LoginContext, UserTypeContext } from "../context/event-context"
import Header from './header';
import Footer from './footer';
import "../styles/style.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  faEye, faEyeSlash
} from '@fortawesome/free-solid-svg-icons'
const LoginForm = () => {
  const [UserName, setUserName] = useState('');
  const [Password, setUserPassword] = useState('');
  const [userState, setUserState] = useState('');
  const [userType, setUserType] = useContext(UserTypeContext);
  const [login, setLogin] = useContext(LoginContext);
  const [showPassword, setShowPassword] = useState(false);


  const userNameHandler = (e) => {
    e.preventDefault();
    let val = e.target.value;
    setUserName(val)
  }
  const userPasswordHandler = (e) => {
    e.preventDefault();
    let val = e.target.value;
    setUserPassword(val)
  }
  const tryLogin = async (e) => {
    e.preventDefault();
    axios.post(`${process.env.GATSBY_API_URL}/loginUser`, { UserName, Password })
      .then((res) => {
        if (res.data) {
          setLogin(true);
          localStorage.setItem("token", res.data.accessToken);
          setUserType(res.data.userType);
          setUserState(res.data.userName);
        } else {
          alert("Incorrect Username or Password")
        }
      })
      .catch((e) => console.error(e));
  }

  return (
    <UserLoginContext.Provider value={[userState, setUserState]} >
      <UserTypeContext.Provider value={[userType, setUserType]} >
        <LoginContext.Provider value={[login, setLogin]}>
          <Header />
          <div className="login-form" style={{
            display: `grid`,
            height: `500px`
          }}>
            <form onSubmit={tryLogin}
              style={{
                height: "10rem",
                maxWidth: "19rem",
              }}
              className="form-group d-flex justify-content-between w-100" >
              <label className='w-100' htmlFor="userName">
                <input className="w-100 form-control" required id="userName" name="userName" placeholder="name" value={UserName} onChange={userNameHandler}></input>
              </label>
              <label className='w-100 d-flex justify-content-end align-items-center position-relative' htmlFor="userPassword">
                <input className='w-100 form-control position-absolute' type={`${showPassword ? 'text' : 'password'}`} required id="userPassword" name="userPassword" placeholder="password**" value={Password} onChange={userPasswordHandler}></input>
                <div onClick={(e) => {
                  e.preventDefault();
                  setShowPassword(!showPassword)
                }
                }>

                  {
                    !showPassword ?
                      <FontAwesomeIcon style={{
                        width: "1rem",
                        zIndex: 2
                      }} className="px-2 h-auto position-relative" icon={faEye} />
                      :
                      <FontAwesomeIcon
                        style={{
                          width: "1rem",
                          zIndex: 2
                        }}
                        className="px-2 h-auto position-relative"
                        icon={faEyeSlash} />
                  }

                </div>

              </label>
              <button className="btn button-custom w-100" type="submit">Login</button>
            </form>
          </div>
        </LoginContext.Provider>
      </UserTypeContext.Provider>
    </UserLoginContext.Provider>
  )
}
export default LoginForm;
